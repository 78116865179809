import React, { useState, useEffect, useRef } from 'react';
import { Radar, RadarChart, PolarGrid, ResponsiveContainer, PolarAngleAxis, PolarRadiusAxis } from 'recharts';

import './Gut.css';

function Gut({ report }) {
  const [diseases, setDiseases] = useState([]);
  const [protectiveGenusData, setProtectiveGenusData] = useState({});
  const [protectiveSpeciesData, setProtectiveSpeciesData] = useState({});
  const [associatedSpeciesData, setAssociatedSpeciesData] = useState({});
  const [associatedGenusData, setAssociatedGenusData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const contentRefs = useRef([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [diseaseDetails, setDiseaseDetails] = useState({});
  const [phylumData, setPhylumData] = useState([]);


  
  useEffect(() => {
    // Existing code to fetch diseases...
  
    diseases.forEach(disease => {
      const url = `https://default.szaportal.com/getDiseaseDetails?diseaseName=${encodeURIComponent(disease.Disease)}&diseaseScore=${disease.Score}`;
      fetch(url)
        .then(response => response.json())
        .then(details => {
          console.log("Fetched details for disease:", disease.Disease, details);
          setDiseaseDetails(prevDetails => ({
            ...prevDetails,
            [disease.Disease]: details
          }));
        })
        
        .catch(error => console.error('Error fetching disease details:', error));
    });
  }, [report, diseases]); // Add diseases as a dependency
  
  const toggleDropdown = (index) => {
    const current = contentRefs.current[index];

    if (activeDropdown === index) {
      current.style.height = '0px';
      setActiveDropdown(null);
    } else {
      current.style.height = `${current.scrollHeight}px`;
      setActiveDropdown(index);
    }
  };

  const searchData = () => {
    // Assuming 'diseases' is an array of objects and each object has a 'Score' property
    const filteredDiseases = diseases
      .filter(d => d.Disease.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => b.Score - a.Score); // Sort in descending order of Score
    return filteredDiseases;
  };
  



  useEffect(() => {
    fetch(`https://default.szaportal.com/getDiseaseScores/${report}`)
      .then(response => response.json())
      .then(data => {
        setDiseases(data);
        // For each disease, fetch its Protective Genus data
        data.forEach(disease => {
          fetch(`https://default.szaportal.com/getProtectiveGenus/${report}/${disease.Disease}`)
            .then(response => response.json())
            .then(genusData => {
              setProtectiveGenusData(prevData => ({
                ...prevData,
                [disease.Disease]: genusData
              }));
            })
            .catch(error => console.error('Error fetching protective genus:', error));
        });
        data.forEach(disease => {
          fetch(`https://default.szaportal.com/getProtectiveSpecies/${report}/${disease.Disease}`)
            .then(response => response.json())
            .then(genusData => {
              setProtectiveSpeciesData(prevData => ({
                ...prevData,
                [disease.Disease]: genusData
              }));
            })
            .catch(error => console.error('Error fetching protective genus:', error));
        });
        data.forEach(disease => {
          fetch(`https://default.szaportal.com/getAssociatedSpecies/${report}/${disease.Disease}`)
            .then(response => response.json())
            .then(genusData => {
              setAssociatedSpeciesData(prevData => ({
                ...prevData,
                [disease.Disease]: genusData
              }));
            })
            .catch(error => console.error('Error fetching protective genus:', error));
        });
        data.forEach(disease => {
          fetch(`https://default.szaportal.com/getAssociatedGenus/${report}/${disease.Disease}`)
            .then(response => response.json())
            .then(genusData => {
              setAssociatedGenusData(prevData => ({
                ...prevData,
                [disease.Disease]: genusData
              }));
            })
            .catch(error => console.error('Error fetching protective genus:', error));
        });




        
      })
      
      .catch(error => console.error('Error fetching disease scores:', error));
  }, [report]);








  useEffect(() => {
    fetch(`https://default.szaportal.com/getPhylumData/${report}`)
      .then(response => response.json())
      .then(data => {
        console.log(data); // This will show you the structure of the fetched data

        setPhylumData(data);
      })
      .catch(error => console.error('Error fetching phylum data:', error));
  }, [report]);
  const formatDataForRadarChart = (data) => {
    return data.map(item => ({
      subject: item.TaxonShortName_cut,
      A: parseFloat(item.PopulationRange.split(' - ')[1]), // upper limit
      B: parseFloat(item.userValue), // user's value
    }));
  };
  
  return (

    <div className="gut">
      Gut Health Analysis
      <div className="gut-report">  

<div className="disease-list-container">
  <input
    type="text"
    className="search"
    placeholder="Search diseases..."
    value={searchTerm}
    onChange={e => setSearchTerm(e.target.value)}
  />
  <div className="disease-list">
  {searchData().map((disease, index) => (
    <div key={index}>
<button onClick={() => toggleDropdown(index)} className="gutdisease-name-btn">
<div className="diseasetitle">

▼   {diseaseDetails[disease.Disease]?.fancyTitle}   </div>

<div className="diseaseprogress">
<div 
className="progress-ball" 
style={{ left: `${Math.round(disease.Score * 100) - 7}%` }} // Adjusts the position of the ball
>
{Math.round(disease.Score * 100)}
</div>
</div>
</button>
      <div
        ref={el => contentRefs.current[index] = el}
        className="dropdown-content"
        style={{
          height: activeDropdown === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0px'
        }}>

<div className="diseasecontenttt">

<div className="titledisease">Definition  </div>
<div className="analysis">{diseaseDetails[disease.Disease]?.definition}</div>


<div className="titledisease">Analysis  </div>

<div className="analysis">{diseaseDetails[disease.Disease]?.analysis}</div>

<div className="titledisease">Suggestion  </div>

<div className="analysis">{diseaseDetails[disease.Disease]?.suggestion}</div>


<div className="dropdownstuff">




<div className="protective-section">

<div className="bacteriasection">

<h3>Protective</h3>

</div>

<div className="list-container">
<div className="genus-list">
  <h4>Genus</h4>
  {protectiveGenusData[disease.Disease]?.map((genus, genusIndex) => (
    <div key={genusIndex} className="genus-item" >
      <p className="genus-name">{genus.ProtectiveGenus}</p>
      <p className="genus-flag">{genus.flag}</p>
    </div>
  ))}
</div>
<div className="species-list">
  <h4>Species</h4>
  {protectiveSpeciesData[disease.Disease]?.map((species, speciesIndex) => (
    <div key={speciesIndex} className="genus-item">
      <p className="genus-name">{species.ProtectiveSpecies}</p>
      
      <p className="genus-flag">{species.flag}</p>
    </div>
  ))}
</div>
</div>
</div>

{/* Associated Section */}
<div className="associated-section">

<div className="bacteriasection">

<h3>Associated</h3>

</div>
<div className="list-container">
<div className="genus-list">
  <h4>Genus</h4>
  {associatedGenusData[disease.Disease]?.map((genus, genusIndex) => (
    <div key={genusIndex} className="genus-item">
<p className="genus-name">{genus.AssociatedGenus}</p>
<p className="genus-flag">{genus.flag}</p>
</div>

  ))}
</div>
<div className="species-list">
  <h4>Species</h4>
  
  {associatedSpeciesData[disease.Disease]?.map((species, speciesIndex) => (
    <div key={speciesIndex} className="genus-item">
      <p className="genus-name">{species.AssociatedSpecies}</p>
      <p className="genus-flag">{species.flag}</p>
    </div>
  ))}


</div>
</div>
</div>
</div>

</div>

</div>


    </div>
  ))}
  
  </div>
  
</div>
<div className="gut-stats">
<h2>Phylum Analysis</h2> 
<div className="radar-chart-container">
<ResponsiveContainer width="100%" height={250}>

<RadarChart data={formatDataForRadarChart(phylumData)}>
<PolarGrid />
<PolarAngleAxis dataKey="subject" />
<Radar name="Upper Limit" dataKey="A" stroke="#20558a" fill="#20558a" fillOpacity={0.6} />
<Radar name="User's Value" dataKey="B" stroke="#2596be" fill="#2596be" fillOpacity={0.6} />
</RadarChart>     </ResponsiveContainer>
</div>
<div className="legend-container">
<div className="legend-item">
<span className="legend-color-box" style={{ backgroundColor: '#20558a' }}></span>Healthy Levels
</div>
<div className="legend-item">
<span className="legend-color-box" style={{ backgroundColor: '#2596be' }}></span>Patient Value
</div>
</div>
<div className="container">

<table>
<thead>
<tr>
  <th>Bacteria</th>
  <th>Population Range</th>
  <th>User Value</th>
  <th>Flag</th>
</tr>
</thead>
<tbody>
{phylumData.map((item, index) => (
  <tr className="desc" key={index}>
    <td>{item.TaxonShortName_cut}</td>
    <td>{item.PopulationRange}</td>
    <td>{item.userValue}</td>
    <td>{item.flag}</td>
  </tr>
))}
</tbody>
</table></div>
</div>

</div>  

    </div>
  );
  
  
}

export default Gut;

