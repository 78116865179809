import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCircleRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import './ReportInputRow.css';

function ReportInputRow({ report, setNewReport, handleAddReport, handleDeleteReport }) {
  const [reportTypes, setReportTypes] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [languageOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    fetch('https://default.szaportal.com/report-types')
      .then((res) => res.json())
      .then((data) => {
        setReportTypes(data);
      })
      .catch((error) => console.error('Error fetching report types:', error));
  }, []);

  const handleReportTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedReportType(selectedType);

    const foundReport = reportTypes.find((r) => r.reportType === selectedType);
    if (foundReport) {
      setLanguageOptions(foundReport.languages);
    } else {
      setLanguageOptions([]);
    }

    setNewReport(report.id, { ...report, ReportType: `${selectedType} (${selectedLanguage})` });
  };

  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    setSelectedLanguage(selectedLang);
    setNewReport(report.id, { ...report, ReportType: `${selectedReportType} (${selectedLang})` });
  };

  // Handle string input for Date of Birth
  const handleDateChange = (e) => {
    setNewReport(report.id, { ...report, PatientDateOfBirth: e.target.value });
  };

  // Handle string input for Gender
  const handleGenderChange = (e) => {
    setNewReport(report.id, { ...report, PatientGender: e.target.value });
  };

  // Only Sample ID is mandatory
  const isReportValid = () => {
    return report.Preset && report.Preset !== '';
  };

  return (
    <div className="inputRow">
      <div className="inputRowI">
        <div className="inputRowIL">
          <div className="ReportTraschan">
            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteReport(report.id)} className="trashIcon" />
          </div>

          {/* Report Type Dropdown */}
          <select className="ReportDropdownI" value={selectedReportType} onChange={handleReportTypeChange}>
            <option value="">Select Report Type</option>
            {reportTypes.map((r) => (
              <option key={r.reportType} value={r.reportType}>
                {r.reportType}
              </option>
            ))}
          </select>

          {/* Language Dropdown */}
          {selectedReportType && (
            <select
              className="ReportDropdownU"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              disabled={!languageOptions.length}
            >
              <option value="">Language</option>
              {languageOptions.map((lang) => (
                <option key={lang} value={lang}>
                  {lang === 'KOR' ? 'Korean' : lang === 'TUR' ? 'Turkish' : lang === 'ENG' ? 'English' : lang}
                </option>
              ))}
            </select>
          )}

          {/* Input fields for First Name, Last Name, Date of Birth, Gender, and Sample ID */}
          {selectedReportType && (
            <>
              <div className="ReportInput">
                <label style={{ fontSize: '10px', fontWeight: 'bold', color: 'gray' }}>First Name (Optional)</label>
                <input
                  type="text"
                  placeholder="Optional"
                  value={report.PatientFirstName}
                  onChange={(e) => setNewReport(report.id, { ...report, PatientFirstName: e.target.value })}
                  className="ReportInputRound"
                />
              </div>

              <div className="ReportInput">
                <label style={{ fontSize: '10px', fontWeight: 'bold', color: 'gray' }}>Last Name (Optional)</label>
                <input
                  type="text"
                  placeholder="Optional"
                  value={report.PatientLastName}
                  onChange={(e) => setNewReport(report.id, { ...report, PatientLastName: e.target.value })}
                  className="ReportInputRound"
                />
              </div>

              <div className="ReportInput">
                <label style={{ fontSize: '10px', fontWeight: 'bold', color: 'gray' }}>Date of Birth (Optional)</label>
                <input
                  type="text"
                  placeholder="Optional"
                  value={report.PatientDateOfBirth}
                  onChange={handleDateChange}
                  className="ReportInputRound"
                />
              </div>

              <div className="ReportInput">
                <label style={{ fontSize: '10px', fontWeight: 'bold', color: 'gray' }}>Gender (Optional)</label>
                <input
                  type="text"
                  placeholder="Optional"
                  value={report.PatientGender || ''}  // Optional field for Gender
                  onChange={handleGenderChange}
                  className="ReportInputRound"
                />
              </div>

              <div className="ReportInput">
                <label style={{ fontSize: '10px', fontWeight: 'bold', color: 'gray' }}>Sample ID (Required)</label>
                <input
                  type="text"
                  value={report.Preset}
                  onChange={(e) => setNewReport(report.id, { ...report, Preset: e.target.value })}
                  className="ReportInputRound"
                  required // Mark Sample ID as required
                />
              </div>
            </>
          )}
        </div>

        {/* Generate Report Button */}
        {selectedReportType && (
          <div className={`addReportButton ${!isReportValid() ? 'disabled' : ''}`} onClick={() => isReportValid() && handleAddReport(report)}>
            <div className="inputRow1">Generate Report</div>
            <div className="inputRow2">
              <FontAwesomeIcon icon={faCircleRight} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportInputRow;
