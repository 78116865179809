import React, { useState, useEffect } from 'react';
import './Login.css'; // Import a CSS file for styles
import logo from './2222.png'; // Make sure to import your logo
const SetPassword = () => {
    const [password, setPassword] = useState('');
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const [userInfo, setUserInfo] = useState({ firstName: '', email: '' });
    const queryParams = new URLSearchParams(window.location.search);
    const userID = queryParams.get('id');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`https://default.szaportal.com/userInfo?id=${userID}`);
                const data = await response.json();
                setIsPasswordSet(data.isPasswordSet);
                setUserInfo({ firstName: data.firstName, email: data.email });
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };
        fetchUserInfo();
    }, [userID]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://default.szaportal.com/setPassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: userID, password })
            });

            if (response.ok) {
                window.location = '/';
            } else {
                console.error('Failed to set password');
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (isPasswordSet) {
        return <div>You have already picked a password.</div>;
    }

    return (

        <div className="login-container">
            
            <div className="login-form">
                
            <div className="login-logo">
                
                    <img src={logo} alt="Login Logo" />
                    <div className="spg">Hello {userInfo.firstName}! 🥳</div>
                    <div className="sph">Please select your password for the following Email:</div>
                    <div className="spm"> {userInfo.email}</div>

                   
                </div>

                <form onSubmit={handleSubmit}>

<div className="form-field">
<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New Password" required />

</div>
<button type="submit" className="login-button">🎉 Set Password</button>
</form>

                
            </div>
            
        </div>


    );
};

export default SetPassword;
