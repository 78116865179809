import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Translate.css'; // Import the CSS file
import Gut from './240605_SILK REPORT_v1_240610 (5)-1.png';
import Genomic from './240605_SILK REPORT_v1_240610 (5)-5.png';
import Oral from './240605_SILK REPORT_v1_240610 (5)-3.png';

const supportedLanguages = ['ko',]; // List of supported languages

const Translate = () => {
    const { lang } = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [reportType, setReportType] = useState('');
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [uneditableColumn, setUneditableColumn] = useState('');
    const [editedCell, setEditedCell] = useState({ row: null, column: null, value: '' });

    const handleLogin = () => {
        // Simulate login with Silk_Translate_User table
        if (email === 'a' && password === 'a') {
            setUser({ email });
        } else {
            alert('Invalid credentials');
        }
    };

    const handleReportSelection = (type) => {
        setReportType(type);
    };

    const handleBackButtonClick = () => {
        setReportType(''); // Reset the report type to show the selection screen
    };

    const fetchData = async (page) => {
        try {
            const response = await fetch(`https://default.szaportal.com/translations/${lang}/${reportType}?page=${page}&limit=20`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setData(result.data);
            setUneditableColumn(result.uneditableColumn);
            setTotalPages(result.totalPages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEditCell = (row, column) => {
        if (column !== uneditableColumn) {
            setEditedCell({ row, column, value: data[row][column] });
        }
    };

    const handleSaveEdit = async () => {
        try {
            const response = await fetch(`https://default.szaportal.com/translations/${lang}/${reportType}/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uneditableValue: data[editedCell.row][uneditableColumn],
                    column: editedCell.column,
                    value: editedCell.value
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Update local state after successful save
            const updatedData = [...data];
            updatedData[editedCell.row][editedCell.column] = editedCell.value;
            setData(updatedData);
            setEditedCell({ row: null, column: null, value: '' });
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditedCell({ row: null, column: null, value: '' });
    };

    const handlePageChange = (e) => {
        const newPage = parseInt(e.target.value, 10);
        if (!isNaN(newPage) && newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };
    const handleValidatedChange = async (rowIndex) => {
        const newValue = data[rowIndex].validated === 1 ? 0 : 1;
        try {
            const response = await fetch(`https://default.szaportal.com/translations/${lang}/${reportType}/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uneditableValue: data[rowIndex][uneditableColumn],
                    column: 'validated',
                    value: newValue
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Update local state after successful save
            const updatedData = [...data];
            updatedData[rowIndex].validated = newValue;
            setData(updatedData);
        } catch (error) {
            console.error('Error updating validated status:', error);
        }
    };
    useEffect(() => {
        if (user && reportType) {
            // Initial fetch
            fetchData(page);

            // Set up interval to refetch data every second
            const intervalId = setInterval(() => {
                fetchData(page);
            }, 1000);

            // Cleanup interval on component unmount or when dependencies change
            return () => clearInterval(intervalId);
        }
    }, [user, reportType, page]);

    if (!supportedLanguages.includes(lang)) {
        return <div>We don't support this language: {lang}</div>;
    }

    if (!user) {
        return (
            <div className="translate-container">
                            <div className="translate-containerl">
                            <div className="translate-containerlo">
                            <div className="translate-containerlog">

                <div className="lo-field">

                <input
                    type="email"
                    className="translate-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                                    </div>
                                    <div className="lo-field">

                <input
                    type="password"
                    className="translate-input"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                                    </div>



                <button className="lo-b" onClick={handleLogin}>Login</button>
                </div>

            </div>
            </div>

            </div>


        );
    }

    if (!reportType) {


        return (
            <div className="translate-container">
                <div className="selection-boxes">


                    <div className="selection-box" onClick={() => handleReportSelection('GenomicReport')}>
                    <div className="selection-box-image" >

                    <img src={Genomic} alt="Login Logo" />
                    </div>

                        Genomic Report
                    </div>
                    <div className="selection-box" onClick={() => handleReportSelection('GutHealthReport')}>
                   
                   
                    <div className="selection-box-image" >
                    <img src={Gut} alt="Login Logo" />
                    </div>



                        Gut Health Report
                    </div>


                    <div className="selection-box" onClick={() => handleReportSelection('OralHealthReport')}>
                    <div className="selection-box-image" >
                    <img src={Oral} alt="Login Logo" />
                    </div>
                        Oral Health Report
                    </div>


                </div>
            </div>
        );


    }

    return (
        <div className="translate-container">

            <div className="translate-header">
                <div className="translate-button" onClick={handleBackButtonClick}> &larr;</div>
                Translations
                <div className="back-button" onClick={handleBackButtonClick}></div>

            </div>

            <div className="m">


            <div className="translation-lower-shi">


                <table className="translation-lower-shhi">
                    <thead>
                        <tr>
                            {data.length > 0 && Object.keys(data[0]).map((col) => (
                                <th className='theadt' key={col}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
    {data.map((row, rowIndex) => (
        <tr key={rowIndex}>
            {Object.keys(row).map((col) => (
                <td
                    key={col}
                    style={{
                        padding: 0,
                        height: '100%',
                        position: 'relative',
                        fontStyle: col === uneditableColumn ? 'italic' : 'normal',
                        cursor: col === uneditableColumn ? 'not-allowed' : 'pointer',
                        pointerEvents: col === uneditableColumn ? 'none' : 'auto',
                    }}
                    onDoubleClick={() => handleEditCell(rowIndex, col)}
                >
                    {col === 'validated' ? (
                        <input
                            type="checkbox"
                            checked={row[col] === 1}
                            onChange={() => handleValidatedChange(rowIndex)}
                        />
                    ) : editedCell.row === rowIndex && editedCell.column === col ? (
                        <div className="editable-cell">
                            <textarea
                                className="editable-textarea"
                                value={editedCell.value}
                                onChange={(e) => setEditedCell({ ...editedCell, value: e.target.value })}
                            />
                            <div className="editable-cell-buttons">
                                <button onClick={handleSaveEdit}>Save</button>
                                <button onClick={handleCancelEdit}>Cancel</button>
                            </div>
                        </div>
                    ) : (
                        <div className="cell-content">{row[col]}</div>
                    )}
                </td>
            ))}
        </tr>
    ))}
</tbody>
                </table>
                </div>


                </div>



            <div className="pagination-controls">
                <div 
                    className="translate-button"
                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
                    disabled={page <= 1}
                    
                >
                    &larr;
                </div>


                <input
                    type="number"
                    className="page-input"
                    value={page}
                    onChange={handlePageChange}
                    min="1"
                    max={totalPages}
                />
                <div
                    className="translate-button"
                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                    disabled={page >= totalPages}
                >
                    &rarr;
                </div>



            </div>
        </div>
    );
};

export default Translate;
