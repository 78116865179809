import React, { useState, useEffect, useRef } from 'react';
import './Gright.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faFileCsv, faFolderClosed, faHome, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import ViewResultsModal from './viewResultsModal';

const Gright = () => {
  const [alleleFrequencies, setAlleleFrequencies] = useState([]);
  const [displayedFrequencies, setDisplayedFrequencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [searchTerm, setSearchTerm] = useState('');
  const [modalSearchTerm, setModalSearchTerm] = useState('');
  const afRef = useRef([]);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [selectedResultFile, setSelectedResultFile] = useState(null);

  useEffect(() => {
    fetchAlleleFrequencies();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = alleleFrequencies.filter(item => 
        item.clinvar.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDisplayedFrequencies(filtered.slice(0, 300));
    } else {
      setDisplayedFrequencies(alleleFrequencies.slice(0, 300));
    }
  }, [searchTerm, alleleFrequencies]);

  const fetchAlleleFrequencies = async () => {
    try {
      const response = await fetch('https://default.szaportal.com/AFCHECKFULL');
      if (!response.ok) {
        throw new Error('Failed to fetch allele frequencies');
      }
      const data = await response.json();
      setAlleleFrequencies(data.allData || []);
      setDisplayedFrequencies((data.allData || []).slice(0, 500));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching allele frequencies:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  const handleViewPatientIDs = (variant, index) => {
    setSelectedVariant(variant);
    const afItem = afRef.current[index];
    const boundingRect = afItem.getBoundingClientRect();
    setModalPosition({
      top: boundingRect.top + window.scrollY + 40,
      left: boundingRect.left,
    });
    setShowModal(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleModalSearch = (event) => {
    setModalSearchTerm(event.target.value);
  };

  const handleViewFiles = () => {
    setShowResultsModal(true);
    setSelectedResultFile(null);
  };

  const handleTableClick = (tableName) => {
    setSelectedResultFile(tableName);
    setShowResultsModal(true);
    setShowModal(false);  // Close the small modal
  };

  const downloadCSV = () => {
    if (!selectedVariant) return;

    // Prepare CSV content
    const csvContent = [
      ['Patients'], // CSV header
      ...selectedVariant.tables.map(id => [id]) // Each ID in a new row
    ]
    .map(e => e.join(',')) // Join each row's values with a comma
    .join('\n'); // Join each row with a newline character

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create a link element, set the download attribute and click it programmatically
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `${selectedVariant.clinvar}.csv`);
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // New function to download all results as a CSV
  const downloadAllResultsCSV = () => {
    if (!alleleFrequencies.length) return;

    // Prepare CSV content
    const csvContent = [
      ['ClinVar', 'Allele Frequency Inhouse', 'Patient IDs'], // CSV header
      ...alleleFrequencies.map(item => [
        item.clinvar, 
        (item.alleleFrequencyInhouse * 100).toFixed(4) + '%', 
        item.tables.join('; ') // Combine patient IDs into a single string separated by semicolons
      ])
    ]
    .map(e => e.join(',')) // Join each row's values with a comma
    .join('\n'); // Join each row with a newline character

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element, set the download attribute and click it programmatically
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'All_Results.csv');
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="GrightO">
      <div className="GrightTO">
        <div className="GRL">
          <div className="GRBUTTON" onClick={handleViewFiles}>View Files</div>
        </div>
        <div className="GRLM">
          <input
            className="GRLMS"
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="GRL">
          <div className="GRBUTTON" onClick={downloadAllResultsCSV}>Export Results</div>
        </div>
      </div>
      <div className="GrightTB">
        <div className="GrightTBI">
          {loading && <div className="loading-message">
            <div class="LMF">

            <div class="dot-spinner">
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
</div>
            
</div>

            
            
            </div>}
          {error && <p className="error-message">{error}</p>}
          {!loading && !error && (
            <div>
              {displayedFrequencies.map((item, index) => (
                <div key={index} className="AFI" ref={el => afRef.current[index] = el}>
                  <div className="AFIL"> 
                    <div className="AFILT">{item.clinvar}</div>
                    <div className="AFILB">{(item.alleleFrequencyInhouse * 100).toFixed(4)}%</div>
                    <div className="AFILBL">
                      <div className="AFILBINL">
                        <div 
                          className="AFLoading-bar" 
                          style={{
                            width: `${item.alleleFrequencyInhouse * 100}%`,
                          }}
                        >
                          <div className="AFLoading-man"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="AFIR">
                    <button className="view-button" onClick={(e) => {
                      e.stopPropagation();
                      handleViewPatientIDs(item, index);
                    }}>
                      ➜
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showModal && selectedVariant && (
        <div className="AFMut" style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}>
          <div className="AFMutO">
            <div className="AFMTSU1">
              <div onClick={() => setShowModal(false)} className="AFMTSL1Q">X</div>
              <div className="A1FT">{selectedVariant.clinvar}</div>
              <div onClick={() => { downloadCSV(); setShowModal(false); }} className="AFMTSL1W">
                <FontAwesomeIcon icon={faFileCsv} />
              </div>
            </div>
            <div className="AFMTSE1">
              <input 
                className="searchbm2" 
                type="text" 
                placeholder="Search..." 
                value={modalSearchTerm}
                onChange={handleModalSearch}
              />
            </div>
            <div className="AFMTSR1">
              {selectedVariant.tables.filter(id => 
                id.toLowerCase().includes(modalSearchTerm.toLowerCase())
              ).map((id, index) => (
                <div 
                  key={index} 
                  className="AFMTSR1-item" 
                  onClick={() => handleTableClick(id)}
                >
                  {id}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {showResultsModal && (
        <ViewResultsModal 
          onClose={() => setShowResultsModal(false)} 
          initialTableName={selectedResultFile}
        />
      )}
    </div>
  );
};

export default Gright;
