import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Login.css';
import logo from './2222.png';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Login attempt with email:', email);
            const response = await fetch('https://filemanager.szaportal.com/api/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();
            if (data.token) {
                Cookies.set('token', data.token, { expires: 180 }); // 180 days for 6 months
                Cookies.set('user', JSON.stringify(data.user), { expires: 180 });
                console.log('Token and user set in cookies:', Cookies.get('token'), Cookies.get('user'));
                navigate('/dashboard');
            } else {
                console.warn('Login failed:', data.message);
                alert(data.message);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <div className="login-logo">
                    <img src={logo} alt="Login Logo" />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email"
                            className="login-input"
                        />
                    </div>
                    <div className="form-field">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Password"
                            className="login-input"
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
