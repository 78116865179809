import React, { useState } from 'react';
import './ReportRow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCheckCircle, faClock, faFileArrowUp, faSpinner, faTimesCircle, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import KoreanFlag from '../flags/1f1f0-1f1f7.svg';
import TurkishFlag from '../flags/1f1f9-1f1f7.svg';
import AmericanFlag from '../flags/1f1fa-1f1f8.svg';
function ReportRow({ item }) {
  const [tooltipStyle, setTooltipStyle] = useState({ display: 'none' });

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Done':
        return { color: 'rgba(16, 137, 16, 0.607)', borderColor: 'rgba(66, 192, 66, 0.607)', icon: faCheckCircle };
      case 'Pending':
        return { color: 'gray', borderColor: 'gray', icon: faClock };
      case 'Processing':
        return { color: 'rgba(255, 193, 7, 0.8)', borderColor: 'rgba(255, 193, 7, 0.8)', icon: faSpinner };
      case 'Failed':
        return { color: 'rgba(255, 0, 0, 0.6)', borderColor: 'rgba(255, 0, 0, 0.6)', icon: faTimesCircle };
      default:
        return { color: 'black', borderColor: 'black', icon: null };
    }
  };

  const handleMouseEnter = (event, email) => {
    // Get the mouse position and set the tooltip style
    setTooltipStyle({
      display: 'block',
      top: event.clientY + 10 + 'px', // Slightly below the mouse
      left: event.clientX + 10 + 'px', // Slightly to the right of the mouse
    });
    console.log(`Hovered on: ${email}`);
  };

  const handleMouseLeave = () => {
    setTooltipStyle({ display: 'none' });
  };

  const statusStyle = getStatusStyle(item.Status);

  return (
    <div className="report-row">
      <div className="RC1">
        
        
      <div className="RT1">
    {item.ReportType ? item.ReportType.replace(/\(.*?\)/, '').trim() : ''}
  </div>
  <div className="RT2">
  {
    item.ReportType && item.ReportType.match(/\((.*?)\)/)
      ? item.ReportType.match(/\((.*?)\)/)[1] === 'KOR'
        ? <img className="flag-image" src={KoreanFlag} alt="Korean Flag" />
        : item.ReportType.match(/\((.*?)\)/)[1] === 'TUR'
          ? <img className="flag-image" src={TurkishFlag} alt="Turkish Flag" />
          : <img className="flag-image" src={AmericanFlag} alt="American Flag" />
      : <img className="flag-image" src={AmericanFlag} alt="American Flag" />
  }
</div>




      </div>





      <div className="RC2">{item.SampleID || ''}</div>
      <div className="RC3">{item.PatientFirstName || ''} {item.PatientLastName || ''}</div>

      <div className="RC4">
        {Array.isArray(item.Recipients)
          ? item.Recipients.map((email, index) => {
              let initials = '';
              const firstDotPos = email.indexOf('.');
              const atPos = email.indexOf('@');

              if (firstDotPos !== -1 && firstDotPos < atPos) {
                initials = email[0] + email[firstDotPos + 1];
              } else {
                initials = email.slice(0, 2);
              }

              return (
                <div
                  key={index}
                  className="REmail"
                  onMouseEnter={(e) => handleMouseEnter(e, email)}
                  onMouseLeave={handleMouseLeave}
                >
                  {initials.toUpperCase()}
                </div>
              );
            })
          : ''}

        <div className="email-tooltip" style={tooltipStyle}>
          {Array.isArray(item.Recipients) && item.Recipients.join(', ')}
        </div>

        
      </div>

      <div className="RC5">
        <div className="RC5t" style={{ color: statusStyle.color, borderColor: statusStyle.borderColor }}>
          {statusStyle.icon && (
            <FontAwesomeIcon icon={statusStyle.icon} style={{ marginRight: '0.5vh' }} />
          )}
          {item.Status || ''}
        </div>
        <div className="RC5b">
          {item.DateTimeInitialized
            ? new Date(item.DateTimeInitialized).toLocaleDateString('en-GB')
            : ''}
        </div>
      </div>

      <div className="RC6" onClick={() => item.ReportLink && window.open(item.ReportLink, '_blank')}>
        {item.ReportLink ? <FontAwesomeIcon icon={faFileArrowUp} /> : <FontAwesomeIcon icon={faFileArrowUp} style={{ color: 'gray', opacity: 0.5 }} />}
      </div>
    </div>
  );
}

export default ReportRow;
