import React from 'react';
import './HomePage.css';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashCalendar from './DashCalendar';

  const data = [
    { name: '1/2/2024', uv: 40 },
    { name: '2/2/2024', uv: 30 },
    { name: '3/2/2024', uv: 20 },
    { name: '4/2/2024', uv: 20 },
    { name: '5/2/2024', uv: 10 },
    { name: '6/2/2024', uv: 20 },
    { name: '7/2/2024', uv: 30 },
  ];

  const legends = [
    { title: "Untested", color: "#6288AD" }, 
    { title: "Received", color: "#4C77A1" },
    { title: "Processing", color: "#366695" },
    { title: "Delivered", color: "#20558A" }  
  ];
  
  const sections = [
    { patients: 50, color: legends[0].color },
    { patients: 25, color: legends[1].color },
    { patients: 25, color: legends[2].color },
    { patients: 37, color: legends[3].color }
  ];
  
  const totalPatients = sections.reduce((total, section) => total + section.patients, 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#20558a', padding: '10px', border: '1px solid #fff', borderRadius: '5px', textAlign: 'center' }}>
          <div style={{ color: '#fff', fontSize: '11px' }}>{`${payload[0].value} registrations`}</div>
          <div style={{ color: 'lightgray', fontSize: '10px', marginTop: '3px' }}>{label}</div>
        </div>
      );
    }
    return null; 
  };
  
  const currentHour = new Date().getHours();
  const greeting = currentHour < 12 ? 'Good morning' : 'Good afternoon';

function HomePage({ user }) {   
    return (
      <div className="dash-container">
        <div className="dash-main">
        <div className="dash-greeting">{greeting},<div className="dash-lastname"> dr. {user.lastName}</div> </div>
          <div className="dash-box-row">

          <div className="dash-box dash-green">
  <div className="dash-box-content">
    <div className="dash-box-toprow">
      <div className="dash-box-header">Patients Connected</div>
      <div className="dash-box-arrow">{'>'}</div>
    </div>
    <div className="dash-box-val"> 
      <div className="dash-box-main-value">120</div>
      <div className="dash-box-secondary-value">/150</div>
      <div className="dash-box-data">
      <div className="dash-box-percentage">80%</div>
    </div>
    </div>
    <div className="dash-box-loading">
      <div className="dash-box-loading-fill" style={{ width: '80%' }}></div>
    </div>
  </div>
            </div>
            <div className="dash-box dash-blue">
            <div className="dash-box-content">
                <div className="dash-box-toprow">
                <div className="dash-box-header1">Reports Generated</div>
                <div className="dash-box-arrow1">{'>'}</div>
                </div>
                <div className="dash-box-val"> 
                <div className="dash-box-main-value1">30</div>
                <div className="dash-box-secondary-value">/150</div>
                <div className="dash-box-data">
                <div className="dash-box-percentage">20%</div>
                </div>
                </div>
                
                <div className="dash-box-loading1">
                <div className="dash-box-loading-fill1" style={{ width: '20%' }}></div>
                </div>
            </div>
            </div>
            <div className="dash-box dash-red">
            <div className="dash-box-content">
                <div className="dash-box-toprow">
                <div className="dash-box-header1">Profiles Completed</div>
                <div className="dash-box-arrow1">{'>'}</div>
                </div>
                <div className="dash-box-val"> 
                <div className="dash-box-main-value1">45</div>
                <div className="dash-box-secondary-value">/150</div>
                <div className="dash-box-data">
                <div className="dash-box-percentage">30%</div>
                </div>
                </div>
                
                <div className="dash-box-loading1">
                <div className="dash-box-loading-fill1" style={{ width: '30%' }}> </div>
                </div>
            </div>
            </div>
            </div>

            <div className="dash-turquoise">
    <div className="avg-title">
        <div>
        <div className="avg-title-main">
            Avg Patient Daily Metrics Input
            <span className="avg-title-subtext">30 days</span>
        </div>
        <div className="avg-title-total">Grand total: 236</div>
        </div>
        <div className="avg-title-approx">~29</div>
    </div>
    <ResponsiveContainer width="100%" height="80%">
    <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#2596be" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#2596be" stopOpacity={0}/>
        </linearGradient>
        </defs>
        <XAxis dataKey="name" tick={false} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip content={<CustomTooltip />} />
        <Area type="monotone" dataKey="uv" stroke="#2596be" fillOpacity={1} fill="url(#colorUv)" />
    </AreaChart>
    </ResponsiveContainer>
        
        
            </div>


          
            <div className="dash-brown">
      <div className="top-part">
      <div className="left-side">
  Omics Test Progression <span className="x-kit">Gut Health Kit</span>
</div>
        <div className="right-side">
          {legends.map((legend, index) => (
            <div key={index} className="legend-item" style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <div
                className="color-box"
                style={{ backgroundColor: legend.color, width: '20px', height: '20px' }}
              ></div>
              <div className="color-boxt">{legend.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="middle-part">Grand total: {totalPatients}</div>
      <div className="bottom-part">
        {sections.map((section, index) => (
          <div key={index} className="patient-section" style={{ width: `${(section.patients / totalPatients) * 100}%` }}>
                        <div  className="patient-count">{section.patients} Patients</div>

            <div
              className="color-section"
              style={{ width: '100%', height: '20px', backgroundColor: section.color }}
            ></div>
          </div>
        ))}
      </div>
            </div>

        </div>

        <div className="dash-sidebar">
        <DashCalendar user={user} />


        </div>
      </div>
    );
  }
  
  export default HomePage;
  