import './Patients.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Range } from 'react-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowAltCircleDown, faArrowsRotate, faBattery0, faCampground, faCancel,
    faCheckCircle, faChevronCircleDown, faCircle, faCircleChevronDown, faClock,
    faCross, faDiagramNext, faFileArchive, faRemoveFormat, faSpinner, faStepForward,
    faTimes, faTruckLoading, faX
} from '@fortawesome/free-solid-svg-icons';

import PatientInsights from './PatientInsights';

// Add 'user' to the function parameter
function Patients({ user }) {
    const navigate = useNavigate();
    const location = useLocation();


    
    const removePatientFromSelection = (userId) => {
        setSelectedForReport(selectedForReport.filter(patient => patient.User_ID !== userId));
    };
    const [showModal, setShowModal] = useState(false);

  


    
    const [sendToPatient, setSendToPatient] = useState(false);
    const [sendToClinician, setSendToClinician] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
const [reportDetails, setReportDetails] = useState([]);

// Function to fetch report details
const fetchReportDetails = async () => {
    try {
        const response = await fetch(`https://default.szaportal.com/fetchReportDetails/${user.id}`);
        if (response.ok) {
            const data = await response.json();
            if (data.success) {
                setReportDetails(data.reports);
            } else {
                console.error(data.message);
            }
        } else {
            console.error('Failed to fetch report details');
        }
    } catch (error) {
        console.error('Error fetching report details:', error);
    }
};

useEffect(() => {
    // Only fetch report details if the modal is not shown
    if (!showModal) {
        const intervalId = setInterval(fetchReportDetails, 1000);

        // Cleanup function to stop fetching when the component unmounts or when showModal changes
        return () => clearInterval(intervalId);
    }
}, [showModal]); // showModal is included in the dependency array to re-evaluate when it changes
 // Empty dependency array means this effect runs once on mount

const [completionPercentage, setCompletionPercentage] = useState(0);
const [doneReports, setDoneReports] = useState(0);
const [totalReports, setTotalReports] = useState(0);


useEffect(() => {
    const total = reportDetails.filter(report => report.Status !== 'Failed').length;
    const done = reportDetails.filter(report => report.Status === 'Done').length;
    const percentage = total > 0 ? Math.round((done / total) * 100) : 0;

    setTotalReports(total);
    setDoneReports(done);
    setCompletionPercentage(percentage);
}, [reportDetails]);


    const toggleTooltip = () => {
        setIsTooltipVisible(!isTooltipVisible);
        setIsReportGenerating(false);
            setSelectedForReport([]); 
            setSelectedReportType('');
    };
    

    const handleSubmitReport = async () => {
        if (!isReportGenerating || selectedReportType === '') return;
    
        for (let patient of selectedForReport) {
            if (patient.isDanger) continue; 
    
            let recipients = [];
            if (sendToPatient) recipients.push(patient.Email);
            if (sendToClinician) recipients.push(user.email); 
    
            const requestData = {
    patientEmail: patient.Email, // Send each report based on the patient's email
    recipients,
    clinicianId: user.id, // Assuming you have the clinician's ID in `user.id`
    reportType: selectedReportType,
    patientFirstName: patient.First_Name,
    patientLastName: patient.Last_Name,
    patientId: patient.User_ID,
};

    
            try {
                const response = await fetch('https://default.szaportal.com/submitReportRequests', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });
    
                const data = await response.json();
                console.log(data.message);
            } catch (error) {
                console.error('Error sending report for patient:', patient.Email, error);
            }
        }
    
        // Close the popup here
        setIsReportGenerating(false);
        setSelectedForReport([]); // Optionally clear selected reports after submission
        toggleTooltip(true)

    };
    
    
    

    
    const handleClick = () => {
        setIsReportGenerating(true);
        setIsTooltipVisible(false);
      };



    const [isReportGenerating, setIsReportGenerating] = useState(false);
    const [selectedForReport, setSelectedForReport] = useState([]);

    const togglePatientSelection = async (patient) => {
        if (selectedReportType) { // Ensure a report type is selected
          try {
            const response = await fetch('https://default.szaportal.com/checkReportStatus', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: patient.User_ID, reportType: selectedReportType }),
            });
            const data = await response.json();
            if (data.status === 'Purchase') {
              // Add a property to your patient object indicating the danger status
              patient.isDanger = true;
            }
          } catch (error) {
            console.error('Error checking report status:', error);
          }
        }
        
        setSelectedForReport(current => {
          const isAlreadySelected = current.find(p => p.User_ID === patient.User_ID);
          if (isAlreadySelected) {
            return current.filter(p => p.User_ID !== patient.User_ID); // Deselect
          } else {
            return [...current, patient]; // Select
          }
        });

      };
      
    const [selectedReportType, setSelectedReportType] = useState('');
    const handleReportTypeChange = async (e) => {
        const newReportType = e.target.value;
        setSelectedReportType(newReportType);
    
        if (!newReportType) { // If no report is selected, clear danger flags
            const updatedPatients = selectedForReport.map(patient => ({ ...patient, isDanger: false }));
            setSelectedForReport(updatedPatients);
            return;
        }
    
        const updatedPatients = await Promise.all(selectedForReport.map(async (patient) => {
            try {
                const response = await fetch('https://default.szaportal.com/checkReportStatus', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: patient.User_ID, reportType: newReportType }),
                });
                const data = await response.json();
                return { ...patient, isDanger: data.status === 'Purchase' };
            } catch (error) {
                console.error('Error re-checking report status:', error);
                return patient; // Return the patient unmodified in case of error
            }
        }));
    
        setSelectedForReport(updatedPatients);
    };
    
    


    const [patients, setPatients] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const getProfilePicUrl = imageName => {
        // Check if imageName is a complete URL
        const isUrl = /^https?:\/\//.test(imageName);
        return isUrl ? imageName : `https://default.szaportal.com/profile_pics/${imageName}`;
      };
    
    useEffect(() => {
        fetchPatients();
        fetchPatients();

        fetchPatients();

    }, []);

    const fetchPatients = async () => {
        try {
            const response = await fetch('https://default.szaportal.com/platformGetPatients');
            if (response.ok) {
                const data = await response.json();
                setPatients(data);
            } else {
                console.error('Failed to fetch patients');
            }
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };

    const openModal = (userId) => {
        setSelectedUserId(userId);
        setShowModal(true);
        setIsTooltipVisible(false);

    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedUserId(null);
        fetchPatients();


    };

    
    const getInitials = (firstName, lastName) => {
        const initials = [
            firstName ? firstName.charAt(0) : '',
            lastName ? lastName.charAt(0) : ''
        ].filter(Boolean).join('').toUpperCase(); // Use filter to remove empty strings
    
        return initials || 'N/A'; // Returns 'N/A' if both firstName and lastName are missing
    };
    
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [filterCountry, setFilterCountry] = useState('');
    const [filterGender, setFilterGender] = useState('');
    const [filterAgeRange, setFilterAgeRange] = useState({ min: 0, max: 100 });
    const [sortMode, setSortMode] = useState('Alphabetical'); // Options: 'Alphabetical', 'Age', 'Country'
    const sortedReportDetails = [...reportDetails].sort((a, b) => {
        const statusPriority = {
            'Processing': 1,
            'Pending': 2,
            'Done': 3,
            'Failed': 4 // Assuming you might have a 'Failed' status or any other status, add them accordingly
        };
    
        // Compare the priorities to determine the order
        return statusPriority[a.Status] - statusPriority[b.Status];
    });
    useEffect(() => {
        let filtered = patients.filter(patient => {
            const firstName = patient.First_Name?.toLowerCase().trim() || '';
            const lastName = patient.Last_Name?.toLowerCase().trim() || '';
            const email = patient.Email?.toLowerCase().trim() || '';
            const searchLower = searchTerm.toLowerCase();
    
            // Determines if the patient's name or email matches the search term
            const matchesSearch = (
                firstName.includes(searchLower) || 
                lastName.includes(searchLower) || 
                email.includes(searchLower) ||
                `${firstName} ${lastName}`.trim().toLowerCase().includes(searchLower) // Checks the full name, handling cases where part of the name might be missing
            );
    
            const matchesCountry = filterCountry ? patient.Country?.toLowerCase().trim() === filterCountry.toLowerCase() : true;
            const matchesGender = filterGender ? patient.Sex?.toLowerCase().trim() === filterGender.toLowerCase() : true;
            const matchesAge = patient.Age >= filterAgeRange.min && patient.Age <= filterAgeRange.max;
    
            return matchesSearch && matchesCountry && matchesGender && matchesAge;
        });
    
        setFilteredPatients(filtered);
    }, [patients, searchTerm, filterCountry, filterGender, filterAgeRange, sortMode]);
    

    const isGenerateDisabled = selectedReportType === '' || selectedForReport.some(patient => patient.isDanger) || (!sendToPatient && !sendToClinician);
    const renderTooltipMessage = () => {
        if (selectedReportType === '') {
            return "Please select a report";
        } else if (!sendToPatient && !sendToClinician) {
            return "Please choose a method of delivery";
        } else {
            return `The following patients are missing a ${selectedReportType}:` + 
                selectedForReport.filter(patient => patient.isDanger).map(patient => 
                    `${patient.First_Name.charAt(0).toUpperCase()}${patient.First_Name.slice(1).toLowerCase()} ${patient.Last_Name.charAt(0).toUpperCase()}${patient.Last_Name.slice(1).toLowerCase()}`
                ).join('');
        }
    
    };



    useEffect(() => {
        console.log("User is this:", user);
    }, [user]); 
    



    return (
<div className="blurting">
           
            {!showModal && (




                <div n>

<div className="search-filter-container">
    <input
        type="text"
        placeholder="Search patients..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
    />

    <select onChange={(e) => setFilterGender(e.target.value)} value={filterGender}>
        <option value="">All Genders</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
    </select>


    <div className="farend">
    <div className="GenerateLoadingBar" >


    <div className="Circledown" onClick={toggleTooltip}>
        

                            
                        </div>

    <div className="patientobserve">


    <div className="Circledown" onClick={toggleTooltip}>
                            <FontAwesomeIcon icon={faCircleChevronDown} />

                            
                        </div>



                        {isTooltipVisible && (
    <div className="TooltipContent">
{sortedReportDetails.map(({ ReportID, PatientFirstName, PatientLastName, PatientEmail, Status }) => (
    PatientEmail && (
        <div className="patientprogress" key={ReportID}>
            <div className={`patient-initials-wrapper`} style={{ background: 'linear-gradient(to bottom, #20558a, #2596be)', opacity: 0.9, borderRadius: '50%', position: 'relative', maxHeight: '4vh', maxWidth: '4vh', minHeight: '4vh', minWidth: '4vh', justifyContent: 'center', alignItems: 'center', fontSize: '10px' }}>
                {((PatientFirstName?.charAt(0) ?? '') + (PatientLastName?.charAt(0) ?? '')).toUpperCase()} 
            </div>

            <div className='patientprogcontainer'>
                <div className="nameinnit">
                    {PatientFirstName} {PatientLastName}
                </div>

                <div className="emailinnit">
                    {PatientEmail}
                </div>
            </div>

            <div className="loadingstatus">
                {Status === 'Pending' && (
                    <div className="pendingshi"> 
                        <div className="yur"> 
                            <FontAwesomeIcon icon={faClock} />
                        </div>
                        Pending
                    </div>
                )}

                {Status === 'Processing' && (
                    <div className="processingshi"> 
                        <div className="yur"> 
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </div>
                        Processing
                    </div>  
                )}

                {Status === 'Done' && (
                    <div className="doneshi">
                        <div className="yur"> 
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </div>
                        Done
                    </div>
                )}

                {Status === 'Failed' && (
                    <div className="failedshi"> 
                        <div className="yur"> 
                            <FontAwesomeIcon icon={faCancel} />
                        </div>
                        Failed
                    </div>   
                )}       
            </div>
        </div>
    )
))}

    </div>
)}

                        </div>
   




                        <div className="LoadingbarContainer">
    <div className="Loadingbar" style={{ width: `${completionPercentage}%` }}>
        <div className="percentageindicator">
            <div className="percentage">
                {completionPercentage}%
            </div>
            <div className="fraction">
                ({doneReports}/{totalReports})
            </div>
        </div>
    </div>
</div>

         

   

    </div>





    <div className="report-generation-container">


    {isReportGenerating ? (
        <div className="GeneratePDFButton" onClick={() => {
            setIsReportGenerating(false);
            setSelectedForReport([]); // Clear selections
            setSelectedReportType(''); // Clear selected report type
        }}>
            <FontAwesomeIcon icon={faTimes} /> 
            <FontAwesomeIcon style={{ color: 'transparent' }} icon={faTimes} /> 
            <div className="GenerateText">Cancel Report</div>

        </div>
    ) : (
<div className="GeneratePDFButton" onClick={handleClick}>
            <FontAwesomeIcon icon={faFileArchive} />
            <div className="GenerateText">Generate Report</div>
        </div>


    )}   
    
    
    
    
    {isReportGenerating && (

        <div className="report-popup">
            <h4>Selected Patients</h4>

            
    
            {selectedForReport.length > 0 ? (
                <>
                    <ul className="selected-patients-list">
                        {selectedForReport.map(patient => (
                            <li key={patient.User_ID} className="patient-item">
    
    
    
                                    <div className="selectedpatientwrap">
    
    
                                    <div className="selectedpatientremove" onClick={() => removePatientFromSelection(patient.User_ID)}>X</div>
    
                                    <div className={`profile-pic-wrapper `} style={{ position: 'relative' }}  >
    
                                    {patient.profile_picture ? (
                            <img src={getProfilePicUrl(patient.profile_picture)} alt="Profile" className="patient-profile-pic" />
                        ) : (
                            <div className={`patient-initials-wrapper`} style={{ background: 'linear-gradient(to bottom, #20558a, #2596be)', opacity: 0.9, borderRadius: '50%', position: 'relative' }}>
                                <div className="patient-initials">
                                    {getInitials(patient.First_Name, patient.Last_Name)}
                                </div>
                            </div>
                        )}
    
    
    </div>
    
    
    
    <div className="patientselectionrow">
    <div className="patientnameholder">
                                    <div className="selectedpatientinfo">
                                    <div className="selectedpatientname">
      {patient.First_Name.charAt(0).toUpperCase() + patient.First_Name.slice(1).toLowerCase()} {patient.Last_Name.charAt(0).toUpperCase() + patient.Last_Name.slice(1).toLowerCase()}
    </div>
    
    
    
    
                                <div className="selectedpatientemail">{patient.Email}</div>
                                </div>
                                </div>
    
    
    
      {patient.isDanger && (
        <>                           
        
         <div className="DangerIconwrap">
         <div className="DangerIconwrapper">
    
                                    <div className="DangerIcon">
    
          ⚠ 
          </div>
    
          <div className="DangerText">
    
          No Report
          </div>
    
          </div>
    
          </div>
    
        </>
      )}

                                </div>
    
                                </div>
    
                                
                            </li>
                        ))}
                    </ul>
                    <div className="bottombutton">
                        <div className="bottomleft">
                        <select className="report-option-dropdown" value={selectedReportType} onChange={handleReportTypeChange}>
      <option value="">Select Report</option>
      <option value="Genomic Report">Whole Genomic Report (ENG)</option>
      <option value="Genomic Report (KOR)">Whole Genomic Report (KOR)</option>

      <option value="Metabolome Lipidome Report (ENG)">Metabolome Lipid Report (ENG)</option>
      <option value="Metabolome Plasma Report (ENG)">Metabolome Plasma Report (ENG)</option>
      <option value="Metabolome Urine Report (ENG)">Metabolome Urine Report (ENG)</option>

      <option value="Gut Health Report (ENG)">Gut Health Report (ENG)</option>
      <option value="Gut Health Report (TUR)">Gut Health Report (TUR)</option>
      <option value="Gut Health Report (KOR)">Gut Health Report (KOR)</option>

      <option value="Oral Health Report (ENG)">Oral Health Report (ENG)</option>
      <option value="Oral Health Report (TUR)">Oral Health Report (TUR)</option>
      <option value="Oral Health Report (KOR)">Oral Health Report (KOR)</option>

      
    </select>
    
                        </div>
                        <div className="bottomright">
                        <div className={`GenerateButton ${isGenerateDisabled ? 'disabled' : ''}`} 
        onClick={() => {
            if (!isGenerateDisabled) {
                handleSubmitReport(); // Correctly invoking the function
            }
        }}>
    
    
    
        <FontAwesomeIcon icon={faFileArchive} />
        <div className={`GenerateTextGen ${isGenerateDisabled ? 'disabled' : ''}`} >Generate Report</div>
        {isGenerateDisabled && (
        <div className="tooltip">
            {renderTooltipMessage()}
        </div>
    )}
    
    </div>
    
                        </div>
                    </div>
                    <div  className="listtypeshi"  >
                    
                    <div className="b" >
    
                    <label className="switch">
            <input type="checkbox" checked={sendToPatient} onChange={() => setSendToPatient(!sendToPatient)} />
      <div className="slider"></div>
      <div className="slider-card">
        <div className="slider-card-face slider-card-front"></div>
        <div className="slider-card-face slider-card-back"></div>
      </div>
                    </label>
                    <div   className="directiontext">
    
                    Send Report to Patient (email)
                    </div>
    
    
    
                    </div>
    
    
                    <div className="b" >
    
    <label className="switch">
    <input type="checkbox" checked={sendToClinician} onChange={() => setSendToClinician(!sendToClinician)} />
    <div className="slider"></div>
    <div className="slider-card">
    <div className="slider-card-face slider-card-front"></div>
    <div className="slider-card-face slider-card-back"></div>
    </div>
    </label>
    <div   className="directiontext">
    
    Send Report to Me (email)
    </div>
    </div>
    
                    
                    </div>
    
    
                </>
            ) : (
                <div className="no-patients-selected">No patients selected</div>
            )}
        </div>
    
    
    
    )}
    
        </div>






</div>




</div>




<div className="patient-container">


                    <div className="patient-table-container">
                        <table className="patient-table">
                            <thead className="patient-table-header">
                                <tr>
                                    <th>Details</th>
                                    <th>Country</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredPatients.map(patient => (
    <tr key={patient.User_ID} className={`patient-table-row ${!isReportGenerating ? 'allow-hover' : ''}`} onClick={(e) => {
        if (isReportGenerating) {
            e.stopPropagation(); // Prevent event bubbling
            togglePatientSelection(patient);
        } else {
            openModal(patient.User_ID);
        }
    }}>
        <td className="patient-table-cell">
            <div className="patient-details">
                <div className={`profile-pic-wrapper ${isReportGenerating ? 'bouncing' : ''}`} style={{ position: 'relative' }}>
                    {patient.profile_picture ? (
                        <img src={getProfilePicUrl(patient.profile_picture)} alt="Profile" className="patient-profile-pic" />
                    ) : (
                        <div className={`patient-initials-wrapper`} style={{ background: 'linear-gradient(to bottom, #20558a, #2596be)', opacity: 0.9, borderRadius: '50%', position: 'relative' }}>
                            <div className="patient-initials">
                                {getInitials(patient.First_Name, patient.Last_Name)}
                            </div>
                        </div>
                    )}
                    {isReportGenerating && selectedForReport.some(p => p.User_ID === patient.User_ID) && (
                        <div className="selection-overlay">
                            <span className="selection-indicator">&#x2713;</span>
                        </div>
                    )}
                </div>
                <div>
                <div className="patient-name">
                {patient.First_Name}{patient.Last_Name ? ` ${patient.Last_Name}` : ''}
</div>                   

 <div className="patient-age">{patient.Sex}, {patient.Age}</div> 
                </div>
            </div>
        </td>
        <td className="patient-table-cell">{patient.Country}</td>
        <td className="patient-table-cell">{patient.Email}</td>
    </tr>
))}

                            </tbody>
                        </table>
                    </div>      
                    </div>      




                </div>
            )} 

{showModal && <PatientInsights userId={selectedUserId} onClose={closeModal} showModal={showModal} user={user} />}
        </div>
    );
}

export default Patients;
