import React, { useEffect, useState } from 'react';
import './Metadata.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faFileCsv,
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

function Metadata({ isOpen, onClose }) {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetch('https://default.szaportal.com/metadata')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Fetched data:', data); // Log the fetched data
          setPatients(data || []); // Use data directly as an array
        })
        .catch((error) => {
          console.error('Error fetching patient data:', error);
          setPatients([]); // Set to an empty array to avoid undefined state
        });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  // Filter patients based on the search term
  const filteredPatients = (patients || []).filter((patient) => {
    const term = searchTerm.toLowerCase();
    return (
      (patient.patient_id && patient.patient_id.toLowerCase().includes(term)) ||
      (patient.name && patient.name.toLowerCase().includes(term)) ||
      (patient.email && patient.email.toLowerCase().includes(term))
    );
  });

  console.log('Filtered Patients:', filteredPatients); // Log the filtered patients

  return (
    <div className="metadataback">
      <div className="metadatapopup">
        <div className="metadatapopuptop">
          <input
            className="metasearch"
            placeholder="Search metadata..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="metaclose" onClick={onClose}>
            X
          </button>
        </div>

        <div className="metarow">
          <div className="metacolumn other">Patient ID</div>
          <div className="metacolumn other1">Account</div>
          <div className="metacolumn other">Name</div>
          <div className="metacolumn other">DoB</div>
          <div className="metacolumn report">Online Reports</div>
        </div>

        <div className="metadatapopupmid">
          <table className="patientmetarowitem">
            <tbody>
              {filteredPatients.length > 0 ? (
                filteredPatients.map((patient) => (
                  <tr key={patient.patient_id} style={{ maxHeight: '2vh'  } } >
                    <td className="patientmetarowitem__id">
                      {patient.patient_id || ''}
                    </td>
                    <td className="patientmetarowitem__account">
                      {patient.email ? (
                        <div className="PACVIEW">
                          <div className="PACVIEWIN">
                            <div className="PACVIEWINLEFT">
                              <div className="PACVIEWINLEFTCIRCLE">
                                {patient.firstname ? patient.firstname[0] : ''}
                                {patient.lastname ? patient.lastname[0] : ''}
                              </div>
                            </div>
                            <div className="PACVIEWINRIGHT">
                              <FontAwesomeIcon icon={faMinusCircle} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="PACVIEWIN">
                          <div className="PACVIEWINLEFTT">
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </div>
                        </div>
                      )}
                    </td>

                    <td className="patientmetarowitem__name">
                      {patient.name || ''}
                    </td>
                    <td className="patientmetarowitem__dob">
                      {patient.date_of_birth || ''}
                    </td>
                    <td className="patientmetarowitem__reports">
                      {patient.reports && patient.reports.length > 0 ? (
                        patient.reports.map((report, index) => (
                          <div
                            key={index}
                            className={`metareportcheckitem ${report
                              .replace(/\s+/g, '')
                              .toLowerCase()}`}
                          >
                            <div
                              className={`metacircle ${report
                                .replace(/\s+/g, '')
                                .toLowerCase()}`}
                            ></div>
                            {report}
                          </div>
                        ))
                      ) : (
                        <div className="blank">_</div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="metadatapopupbot">
          <div className="MDCSV">
            <FontAwesomeIcon
              icon={faFileCsv}
              style={{ paddingLeft: '5px' }}
            />
          </div>

          <div className="MDADD">
            <FontAwesomeIcon icon={faAdd} style={{ paddingLeft: '5px' }} />
            <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>
              Add Patient
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Metadata;
