import React, { useEffect, useState } from 'react';
import './ReportGenerator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faFileCode } from '@fortawesome/free-solid-svg-icons';
import ReportRow from './ReportRow';
import ReportInputRow from './ReportInputRow';

function ReportGenerator({ user }) {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [inputRows, setInputRows] = useState([]); // Manage multiple rows of reports

  useEffect(() => {
    const fetchData = () => {
      fetch('https://default.szaportal.com/api/report_requests')
        .then((response) => response.json())
        .then((data) => {
          setItems(data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const addNewInputRow = () => {
    const newRow = {
      id: Date.now(),
      Recipients: '',
      DateTimeInitialized: '',
      ReportType: '',
      Status: '',
      PatientFirstName: '',
      PatientLastName: '',
      PatientID: '',
      Preset: '',
      ReportLink: '',
      PatientDateOfBirth: '', // Added field for Date of Birth
      PatientGender: '', // Added field for Gender (optional)
    };
    setInputRows([...inputRows, newRow]);
  };
  

  const setNewReport = (id, updatedReport) => {
    setInputRows(inputRows.map(row => (row.id === id ? updatedReport : row)));
  };

  const handleAddReport = (report) => {
    fetch('https://default.szaportal.com/api/report_requests', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(report),
    })
      .then((response) => response.json())
      .then((data) => {
        setInputRows(inputRows.filter((row) => row.id !== report.id)); // Remove the row after submitting
      })
      .catch((error) => {
        console.error('Error adding report:', error);
      });
  };

  const handleDeleteReport = (id) => {
    setInputRows(inputRows.filter(row => row.id !== id));
  };

  const matchesSearch = (item, searchTerm) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    const recipients = Array.isArray(item.Recipients) ? item.Recipients.join(', ') : item.Recipients;
    const fullName = `${item.PatientFirstName || ''} ${item.PatientLastName || ''}`.toLowerCase();

    return (
      (item.ReportID || '').toString().toLowerCase().includes(lowerSearchTerm) ||
      (item.PatientEmail || '').toLowerCase().includes(lowerSearchTerm) ||
      (recipients || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.DateTimeInitialized || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.ClinicianID || '').toString().toLowerCase().includes(lowerSearchTerm) ||
      (item.ReportType || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.Status || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.PatientFirstName || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.PatientLastName || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.PatientID || '').toString().toLowerCase().includes(lowerSearchTerm) ||
      (item.Preset || '').toLowerCase().includes(lowerSearchTerm) ||
      (item.PatientDateOfBirth || '').toLowerCase().includes(lowerSearchTerm) || // Search by Date of Birth
      (item.ReportLink || '').toLowerCase().includes(lowerSearchTerm) ||
      fullName.includes(lowerSearchTerm)
    );
  };

  const filteredItems = items.filter((item) => {
    const searchTerms = searchQuery.trim().toLowerCase().split(' ');
    return searchTerms.every((term) => matchesSearch(item, term));
  });

  return (
    <div className="RGF">
      <div className="RGT">
        <div className="RGTI">
          <div className="RGTIL">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="RGTILsearch"
            />
          </div>
          <div className="RGTIR">
            <div className="RGTIRL" onClick={addNewInputRow}>+</div>
          </div>
        </div>
      </div>

      <div className="RGB">
        {inputRows.map((report) => (
          <ReportInputRow
            key={report.id}
            report={report}
            setNewReport={setNewReport}
            handleAddReport={handleAddReport}
            handleDeleteReport={handleDeleteReport}
          />
        ))}
        {filteredItems.map((item, index) => (
          <ReportRow key={index} item={item} />
        ))}
      </div>
    </div>
  );
}

export default ReportGenerator;
