import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './Login';
import Register from './Register';
import Dashboard from './Dashboard';
import PatientActivation from './patient-activation';
import SetPassword from './SetPassword';
import Translate from './Translate';
import Scanner from './Orders/Scanner.js';
import Filetransfer from './filetransfer/Filetransfer.js';
import PrivacyPolicy from './PrivacyPolicy'; // Import the Privacy Policy component

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<NavigateToDashboardOrLogin />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/patient-activation" element={<PatientActivation />} />
                <Route path="/dashboard" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route path="/filetransfer1" element={
                    <ProtectedRoute>
                        <Filetransfer />
                    </ProtectedRoute>
                } />
                <Route path="/translate/:lang" element={<Translate />} />
                <Route path="/ScanEx" element={<Scanner />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add the route for the Privacy Policy */}
            </Routes>
        </Router>
    );
}

const NavigateToDashboardOrLogin = () => {
    const token = Cookies.get('token');
    console.log('NavigateToDashboardOrLogin - token:', token);
    return token ? <Navigate to="/dashboard" /> : <Login />;
};

const ProtectedRoute = ({ children }) => {
    const token = Cookies.get('token');
    console.log('ProtectedRoute - token:', token);

    if (!token) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default App;
