import React, { useState, useEffect } from 'react';
import './smr.css';

const GmrComponent = ({ selectedGenes, closeGeneModal }) => {
    const [omimDiseases, setOmimDiseases] = useState({});

    useEffect(() => {
        const fetchOmimDiseases = async () => {
            const diseases = {};
            for (const item of selectedGenes) {
                const geneName = item.gene.current_hgnc_symbol;
                try {
                    const response = await fetch('https://default.szaportal.com/fetchomimdiseases', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            geneName: geneName
                        }),
                    });
                    const data = await response.json();
                    diseases[geneName] = data;
                } catch (error) {
                    console.error(`Error fetching diseases for ${geneName}:`, error);
                    diseases[geneName] = [];
                }
            }
            setOmimDiseases(diseases);
        };

        fetchOmimDiseases();
    }, [selectedGenes]);

    return (
        <>
            {selectedGenes.map((item, index) => (
                <div 
                    key={`gene-modal-${index}`}
                    className="mutationOmodal"
                    style={{
                        position: 'fixed',
                        left: `${item.position.x}px`,
                        top: `${item.position.y}px`,
                    }}
                >
                    <div className="MTOPS">         
                        <div onClick={() => closeGeneModal(index)} className="MTOPSL">X</div>
                        <div className="MTOPSM"> {item.gene.current_hgnc_symbol}  </div>
                        <div className="MTOPSR"></div>
                    </div>

                    <div className="MTOPSB">
                        <div className="MTOPSB-row">
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Gene Symbol</div>
                                <div className="MTOPSB-content">{item.gene.current_hgnc_symbol}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Gene ENSG</div>
                                <div className="MTOPSB-content">{item.gene.ensembl_gene_ensg}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Reverse Strand?</div>
                                <div className="MTOPSB-content">
                                    {item.gene.is_reverse_strand === 1 ? 'Yes' : 'No'}
                                </div>
                            </div>
                        </div>
                        <div className="MTOPSB-row">
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">HGNC Description</div>
                                <div className="MTOPSB-content">{item.gene.hgnc_description}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Gene Location</div>
                                <div className="MTOPSB-content">{item.gene.chr}:{item.gene.start} - {item.gene.end}</div>
                            </div>
                        </div>
                    </div>

                    <div className="MTOPSB">
                        <div className="MTOPSB-titleIN">OMIM Diseases</div>
                        <div className="MTOPSB-contentI">
                            {omimDiseases[item.gene.current_hgnc_symbol] ? 
                                omimDiseases[item.gene.current_hgnc_symbol].map((disease, i) => (
                                    <div key={i} className="disease-item5">
                                        <div className="disease-name5">{disease.disease_name}</div>
                                        <div className="disease-details5">
                                            <span>ID: {disease.id}</span>
                                            <span>HGNC Symbol: {disease.ensembl_hgnc_symbol}</span>
                                            <span>OMIM Morbid ID: {disease.omim_morbid_id}</span>
                                            <span>Association Type: {disease.association_type}</span>
                                            <span>Inheritance Mode: {disease.inheritance_mode}</span>
                                        </div>
                                    </div>
                                ))
                                : 'Loading...'}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default GmrComponent;