import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useUserData = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = Cookies.get('token');
      if (token) {
        try {
          const response = await fetch('https://filemanager.szaportal.com/api/user', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            const userData = await response.json();
            setUser(userData);
            Cookies.set('user', JSON.stringify(userData), { expires: 180 }); // Update the user cookie
          } else {
            console.error('Failed to fetch user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  return user;
};

export default useUserData;
