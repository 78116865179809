import React, { useState, useEffect } from 'react';
import './Login.css'; // Import a CSS file for styles
import logo from './2222.png'; // Make sure to import your logo
import google from './Google_Play_Store_badge_EN.svg.png'; // Make sure to import your logo
import apple from './pngwing.com (1).png'; // Make sure to import your logo


const PatientActivation = () => {
    const [password, setPassword] = useState('');
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const [userInfo, setUserInfo] = useState({ firstName: '', email: '' });
    const queryParams = new URLSearchParams(window.location.search);
    const identifier = queryParams.get('info');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`https://default.szaportal.com/info?info=${identifier}`);
                const data = await response.json();
                setIsPasswordSet(data.isPasswordSet);
                setUserInfo({ firstName: data.firstName, email: data.email });
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };
        fetchUserInfo();
    }, [identifier]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://default.szaportal.com/patientpassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ identifier: identifier, password })
            });

            if (response.ok) {
                setIsPasswordSet(true);            
            } else {
                console.error('Failed to set password');
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (isPasswordSet) {
        return <div className="login-container">
            
        <div className="login-form">
            
        <div className="login-logo">
            
                <img src={logo} alt="Login Logo" />
                <div className="spg">Wonderful {userInfo.firstName}! 🥳</div>
                <div className="sph">You have activated your account!</div>
                <div className="sph">Download the App at Google Store or Apple Store and Log In</div>
                <div className="downloadcontainer">
  <a href="https://play.google.com/store/apps/details?id=com.szalongevity.sza_mobile">
    <img className="google" src={google} alt="Google Play Store Logo" />
  </a>
  <a href="https://apps.apple.com/app/sza-longevity/id6468774325?l=en-GB">
    <img className="apple" src={apple} alt="Apple App Store Logo" />
  </a>
</div>


               
            </div>

            

            
        </div>
        
    </div>





    }

    return (

        <div className="login-container">
            
            <div className="login-form">
                
            <div className="login-logo">
                
                    <img src={logo} alt="Login Logo" />
                    <div className="spg">Hello {userInfo.firstName}! 🥳</div>
                    <div className="sph">Please select your password for the following Email:</div>
                    <div className="spm"> {userInfo.email}</div>

                   
                </div>

                <form onSubmit={handleSubmit}>

<div className="form-field">
<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New Password" required />

</div>
<button type="submit" className="login-button">Set Password</button>
</form>

                
            </div>
            
        </div>


    );
};

export default PatientActivation;
