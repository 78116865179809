import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faChartLine, faSignOutAlt, faBell, faUserPlus, faBars, faDna, faSoap, faHandsBubbles, faHouseFloodWater, faWater, faMicroscope, faFilePdf, faCloud, faCloudUpload, faFilePowerpoint, faFile } from '@fortawesome/free-solid-svg-icons';
import Homepage from './HomePage';
import Patients from './Patients';
import Analytics from './Analytics';
import Communications from './Communications';
import Discoveromics from './discoveromics/Discoveromics.js';
import ReportGenerator from './ReportGenerator/ReportGenerator.js';
import MetaUpload from './MetaUpload/MetaUpload.js';

import Image from './image.js';
import InvitePatients from './Invitepatients';
import SettingsModal from './SettingsModal';
import './Dashboard.css';
import logo from './111111.png';

function Dashboard() {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState('homepage');
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            setUser(JSON.parse(userCookie));
        } else {
            navigate('/login');
        }
    }, [navigate]);

    if (!user) {
        return null;
    }

    const handleSignOut = () => {
        Cookies.remove('token');
        Cookies.remove('user');
        navigate('/login');
    };

    const closeAddUserModal = () => {
        setIsAddUserModalOpen(false);
    };

    const closeSettingsModal = () => {
        setIsSettingsModalOpen(false);
    };

    const renderAddUserModal = () => {
        return (
            <div className={`addusermodal ${isAddUserModalOpen ? 'open' : ''}`}>
                <div className="addusercontent">
                    <div className="adduserclose" onClick={closeAddUserModal}>✖</div>
                    <InvitePatients />
                </div>
            </div>
        );
    };

    const renderSettingsModal = () => {
        return (
            <div className={`addusermodal ${isSettingsModalOpen ? 'open' : ''}`}>
                <div className="settingaddusercontent">
                    <SettingsModal user={user} closeSettingsModal={closeSettingsModal} />
                </div>
            </div>
        );
    };

    const toggleSettingsModal = () => {
        setIsSettingsModalOpen(!isSettingsModalOpen);
    };

    const toggleAddUserModal = () => {
        setIsAddUserModalOpen(!isAddUserModalOpen);
    };

    const getNavItemClass = (tabName) => {
        return `nav-item ${selectedTab === tabName ? 'selected' : ''}`;
    };

    return (
        <div className="dashboard-container">
            {isAddUserModalOpen && renderAddUserModal()}
            {isSettingsModalOpen && renderSettingsModal()}

            <div className="sidebar">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <ul>
                    <li className={getNavItemClass('homepage')} onClick={() => setSelectedTab('homepage')}>
                        <FontAwesomeIcon icon={faHome} className="outline-icon" />
                    </li>
                    <li className={getNavItemClass('patients')} onClick={() => setSelectedTab('patients')}>
                        <FontAwesomeIcon icon={faUsers} className="outline-icon" />
                    </li>
                   
                    {(user?.role === 'Admin+' || user?.role === 'Admin++') && (
                        <li className={getNavItemClass('analytics')} onClick={() => setSelectedTab('analytics')}>
                            <FontAwesomeIcon icon={faChartLine} className="outline-icon" />
                        </li>
                    )}


                        <li className={getNavItemClass('discoveromics')} onClick={() => setSelectedTab('discoveromics')}>
                        <FontAwesomeIcon icon={faMicroscope} className="outline-icon" />
                        </li>

                        <li className={getNavItemClass('reportgenerator')} onClick={() => setSelectedTab('reportgenerator')}>
                        <FontAwesomeIcon icon={faFile} className="outline-icon" />
                        </li>

                        
                        {(user?.role === 'Admin++' || user?.role === 'Admin++') && (
                        <li className={getNavItemClass('metaupload')} onClick={() => setSelectedTab('metaupload')}>
                        <FontAwesomeIcon icon={faCloudUpload} className="outline-icon" />
                        </li>
                    )}

                    {(user?.role === 'image' ) && (
                        <li className={getNavItemClass('image')} onClick={() => setSelectedTab('image')}>
                            <FontAwesomeIcon icon={faChartLine} className="outline-icon" />
                        </li>
                    )}
                </ul>
                <div className="notification-bell">
                    <FontAwesomeIcon icon={faSignOutAlt} onClick={handleSignOut} className="outline-icon" />
                </div>
            </div>

            <div className="main-content">
                <div className="top-bar">
                    <div className="hey-bar">
                        <div className="binitials-circle">
                            {user?.firstName?.[0].toUpperCase()}{user?.lastName?.[0].toUpperCase()}
                        </div>
                        <div className="text-container">
                            <span className="greeting">Hello,</span>
                            <span className="name">{user?.firstName} {user?.lastName}</span>
                        </div>
                        <div className="text-x">
                            <span className="toggleicon">
                                <FontAwesomeIcon icon={faBars} onClick={toggleSettingsModal} className="outline-icon" />
                            </span>
                        </div>
                    </div>
                    <div className="role">{user?.role}</div>
                    <button className="Topbarbuttons">
                        <FontAwesomeIcon icon={faBell} />
                    </button>
                    <button onClick={toggleAddUserModal} className="Topbarbuttons">
                        <FontAwesomeIcon icon={faUserPlus}/>
                    </button>
                </div>
                <div className="content-area">
                    {selectedTab === 'homepage' && <Homepage user={user} />}
                    {selectedTab === 'analytics' && <Analytics user={user} />}
                    {selectedTab === 'communications' && <Communications user={user} />}
                    {selectedTab === 'patients' && <Patients user={user} />}
                    {selectedTab === 'image' && <Image user={user} />}
                    {selectedTab === 'discoveromics' && <Discoveromics user={user} />}
                    {selectedTab === 'reportgenerator' && <ReportGenerator user={user} />}
                    {selectedTab === 'metaupload' && <MetaUpload user={user} />}

                </div>
            </div>
        </div>
    );
}

export default Dashboard;
