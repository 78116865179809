
import React, { useState, useEffect, useRef } from 'react';
import './Genomics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Papa} from "papaparse"
import * as XLSX from 'xlsx';

import {  faEyeSlash, faEye, faPlusCircle, faArrowLeft, faFileCsv, faCheck, faSave, faFilter, faPen, faTrash, faCirclePlus, faHeartCircleBolt, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

function Genomics({ report, user }) {
  const [genomicData, setGenomicData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Whole Genome');
  const [popupVisible, setPopupVisible] = useState(false);
  const [geneList, setGeneList] = useState([]);
  const [geneSearchTerm, setGeneSearchTerm] = useState('');
  const [selectedGenes, setSelectedGenes] = useState([]);
  const [temporaryFilterActive, setTemporaryFilterActive] = useState(false);
  const [temporarySelectedGenes, setTemporarySelectedGenes] = useState([]);
  const templateScrollRef = useRef(null);

  const [templates, setTemplates] = useState([]);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null); // Tracks the selected template index
  const [newTemplateName, setNewTemplateName] = useState("New Template");
  const [isNewTemplateAdded, setIsNewTemplateAdded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedDisease, setSelectedDisease] = useState(null);

  const [isGenomicModalVisible, setGenomicModalVisible] = useState(false);
  const toggleGenomicModal = () => {
    if (isGenomicModalVisible) {
      setSelectedDisease(null); // Clear selected disease when closing the modal
    }
    setGenomicModalVisible(!isGenomicModalVisible);
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const genesFromExcel = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            .map(row => row[0]) 
            .filter(gene => gene);
        genesFromExcel.forEach(gene => {
            if (!geneList.some(g => g.Gene === gene)) {
                addGeneAndUpdateSelection(gene);
            } else if (!temporarySelectedGenes.includes(gene)) {
                setTemporarySelectedGenes(prev => [...prev, gene]);
            }
        });
    };

    reader.readAsArrayBuffer(file);
};
const addGeneAndUpdateSelection = (geneToAdd) => {
  fetch('https://default.szaportal.com/addGene', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ gene: geneToAdd }),
  })
  .then(response => response.json())
  .then(() => {
      fetchGenes().then(() => {
          if (!temporarySelectedGenes.includes(geneToAdd)) {
              setTemporarySelectedGenes(prevGenes => [...prevGenes, geneToAdd]);
          }
      });
  })
  .catch(error => console.error('Error adding gene:', error));
};



  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setSelectedTemplateIndex(null);

  };
  const fetchGenes = () => {
    return fetch('https://default.szaportal.com/getGenes')
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then(GeneListInfo => {
        setGeneList(GeneListInfo);
      })
      .catch(error => console.error('There was an error fetching the genes:', error));
  };
  
  useEffect(() => {
    fetchGenes(); 
  }, []);

  const handleTemplateNameChange = (newName, idx) => {
    const updatedTemplates = templates.map((template, templateIdx) => {
      if (idx === templateIdx) {
        return { ...template, tempName: newName };
      }
      return template;
    });
    setTemplates(updatedTemplates);
  };
  
  
  const deleteTemplate = (templateId) => {
    fetch(`https://default.szaportal.com/deleteTemplate/${templateId}`, {
      method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Remove the template from the state to update UI
        const updatedTemplates = templates.filter(template => template.TemplateID !== templateId);
        setTemplates(updatedTemplates);
        console.log('Template deleted successfully');
      }
    })
    .catch(error => console.error('Error deleting template:', error));
  };
  
  
  const saveTemplateChanges = (templateId, newName) => {
    fetch(`https://default.szaportal.com/updateTemplateName/${templateId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newTemplateName: newName }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Update the template name in the state to reflect the change in the UI
        const updatedTemplates = templates.map(template => {
          if (template.TemplateID === templateId) {
            return { ...template, TemplateName: newName };
          }
          return template;
        });
        setTemplates(updatedTemplates);
        console.log('Template name updated successfully');
        setIsEditMode(false);

      }
    })
    .catch(error => console.error('Error updating template name:', error));
  };
  

  
  const newTemplateRef = useRef(null); // Ref for the new template element

  function reorderEntries(disease) {
    const priorityKeys = ['selCSQ', 'DiseaseInfo', 'ClinVar_CLNDISDB', 'INFO'];
    const excludeKeys = ['index', 'REPORT_TAG'];
    // Filter out entries to exclude, then separate remaining entries into priority and non-priority
    const filteredEntries = Object.entries(disease).filter(([key]) => !excludeKeys.includes(key));
    const reorderedEntries = filteredEntries.filter(([key]) => !priorityKeys.includes(key));
    const priorityEntries = filteredEntries.filter(([key]) => priorityKeys.includes(key));
  
    return [...reorderedEntries, ...priorityEntries];
  }
  

  useEffect(() => {
    const clinicianId = user.id;
    fetch(`https://default.szaportal.com/fetchTemplatesWithGenes/${clinicianId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.templates) {
          setTemplates(data.templates); // Save fetched templates
        }
      })
      .catch(error => console.error('Error fetching templates with genes:', error));
  }, [user.id]);
  
  
  // Add logic for when a template is selected, to auto-select the genes
const handleTemplateClick = (idx) => {
  if (selectedTemplateIndex === idx) {
    setSelectedTemplateIndex(null);
    setTemporarySelectedGenes([]);
  } else {
    setSelectedTemplateIndex(idx);
    setTemporarySelectedGenes(templates[idx].Genes); 
  }
};

 

  const calculateStepSize = () => {
    if (templateScrollRef.current) {
      const containerWidth = templateScrollRef.current.offsetWidth * 0.7;
      let stepSize = 0;
      let accumulatedWidth = 0;
  
      Array.from(templateScrollRef.current.children).some(child => {
        const childWidthWithMargin = child.offsetWidth + parseInt(window.getComputedStyle(child).marginRight, 10);
        if (accumulatedWidth + childWidthWithMargin > containerWidth) {
          return true;
        }
        accumulatedWidth += childWidthWithMargin;
        stepSize += childWidthWithMargin;
        return false; // Continue to next child
      });
  
      return stepSize;
    }
    return 0;
  };
  const [dynamicButtonIcon, setDynamicButtonIcon] = useState(faFileCsv); // Default to CSV upload icon

  useEffect(() => {
    setDynamicButtonIcon(temporarySelectedGenes.length > 0 ? faSave : faFileCsv);
  }, [temporarySelectedGenes]);

  const handleDynamicButtonClick = () => {
    if (temporarySelectedGenes.length > 0 || selectedGenes.length > 0) {
      const uniqueGenes = Array.from(new Set([...temporarySelectedGenes, ...selectedGenes]));
      const clinicianId = user.id; 
  
      fetch('https://default.szaportal.com/saveTemplateWithGenes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ clinicianId, templateName: newTemplateName, genes: uniqueGenes }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const newTemplate = { TemplateName: newTemplateName, Genes: uniqueGenes, TemplateID: data.templateId };
          setIsEditMode(false);

          setTemplates([...templates, newTemplate]);
          setSelectedTemplateIndex(templates.length); 
        }
      })
      .catch(error => console.error('Error saving new template:', error));
    } else {
      fileInputRef.current.click();
    }
    setIsNewTemplateAdded(true); 
    setTimeout(() => setIsNewTemplateAdded(false), 2000); 

  };


  useEffect(() => {
    if (templateScrollRef.current && templates.length > 0) {
      templateScrollRef.current.scrollTo({
        left: templateScrollRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [templates.length]);

  const fetchTemplates = () => {
    const clinicianId = user.id;
    fetch(`https://default.szaportal.com/fetchTemplatesWithGenes/${clinicianId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.templates) {
          setTemplates(data.templates);
        }
      })
      .catch(error => console.error('Error fetching templates with genes:', error));
  };
  
  useEffect(() => {
    fetchTemplates();
  }, [user.id]);
  


useEffect(() => {
  const matchCellHeights = () => {
    const grids = document.querySelectorAll('.gridd');

    grids.forEach(grid => {
      grid.querySelectorAll('.celll').forEach(cell => {
        cell.style.height = 'auto';
      });

      const cellHeights = Array.from(grid.querySelectorAll('.celll')).map(
        cell => cell.offsetHeight
      );
      const tallestHeight = Math.max(...cellHeights);
      grid.querySelectorAll('.celll').forEach(cell => {
        cell.style.height = `${tallestHeight}px`;
      });
    });
  };

 
  matchCellHeights();
  window.addEventListener('resize', matchCellHeights);

  return () => window.removeEventListener('resize', matchCellHeights);
}, [selectedDisease]);


  const scrollTemplates = (direction) => {
    const stepSize = calculateStepSize();
    if (templateScrollRef.current) {
      let newScrollPosition = direction === 'left'
        ? Math.max(templateScrollRef.current.scrollLeft - stepSize, 0)
        : Math.min(templateScrollRef.current.scrollLeft + stepSize, templateScrollRef.current.scrollWidth);
  
      templateScrollRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  
    }
  };

  const handleAddGene = () => {
    const geneToAdd = geneSearchTerm; // The new gene to add
    fetch('https://default.szaportal.com/addGene', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ gene: geneToAdd }),
    })
    .then(response => response.json())
    .then(() => {
      fetchGenes().then(() => {

        setTemporarySelectedGenes(prevGenes => [...prevGenes, geneToAdd]);
      });
    })
    .catch(error => console.error('Error adding gene:', error));
  };
  
  

const checkForOverflow = () => {
  if (templateScrollRef.current) {
    const { scrollWidth, clientWidth, scrollLeft } = templateScrollRef.current;
    const atStart = scrollLeft === 0;
    const atEnd = scrollLeft >= scrollWidth - clientWidth;
  }
};
  
  useEffect(() => {
    checkForOverflow();
  }, [geneList]); 
  
  const handleApplyGenes = () => {
    setIsEditMode(false);

    togglePopup();
    setSelectedGenes(temporarySelectedGenes);
    setSelectedFilter('Whole Genome'); 
    if (temporarySelectedGenes.length === 0) {
        setIsFilterLocked(false); 
    } else {
        setIsFilterLocked(true); 
    }
};

  const [isFilterLocked, setIsFilterLocked] = useState(false);
  const handleClearFilters = () => {
    setIsEditMode(false);
    setSelectedGenes([]);
    setIsFilterLocked(false);
    setTemporaryFilterActive(false); 
    setTemporarySelectedGenes([]); 
    setSelectedTemplateIndex(null);
  };
  



  const renderGeneList = () => {
    const filteredAndSortedGenes = geneSearchTerm
      ? geneList.filter(gene => gene.Gene.toLowerCase().includes(geneSearchTerm.toLowerCase()))
      : geneList;
  
    filteredAndSortedGenes.sort((a, b) => {
      const aIsSelected = temporarySelectedGenes.includes(a.Gene);
      const bIsSelected = temporarySelectedGenes.includes(b.Gene);
  
      if (aIsSelected && !bIsSelected) return -1;
      if (!aIsSelected && bIsSelected) return 1;
      return a.Gene.localeCompare(b.Gene);
    });
  
    return (
      <>
        {filteredAndSortedGenes.length > 0 ? (
          <div className="gene-list">
            {filteredAndSortedGenes.map((gene, index) => (
              <span 
                key={index} 
                className={`gene-item ${temporarySelectedGenes.includes(gene.Gene) ? 'selected' : ''}`}
                onClick={() => handleGeneSelection(gene.Gene)}
              >
                {temporarySelectedGenes.includes(gene.Gene) ? <FontAwesomeIcon icon={faCheck} /> : '+'} {gene.Gene}
              </span>
            ))}
          </div>
        ) : (
          geneSearchTerm && (
            <div className="genomics-popup__add-message">
              <p><strong>{geneSearchTerm}</strong> was not found, would you like to add it to the database?</p>
              <button onClick={handleAddGene} className="genomics-popup__add-btn">+ Add</button>
            </div>
          )
        )}
      </>
    );
  };
  


  
  
  const handleGeneSelection = (gene) => {
    setTemporarySelectedGenes(prevGenes => {
      const newGenes = prevGenes.includes(gene) ?
        prevGenes.filter(g => g !== gene) :
        [...prevGenes, gene];
  
      setSelectedTemplateIndex(null); 
      return newGenes;
    });
  };
  const filterDiseasesByGenes = () => {
    if (selectedGenes.length === 0) return genomicData;
  
    return genomicData.filter(disease => {
      return disease.Genes.split(',').some(diseaseGene => selectedGenes.includes(diseaseGene.trim()));
    });
  };
  
  const togglePopup = () => {
    setIsEditMode(false);

    setPopupVisible(!popupVisible);
    if (!popupVisible) { 
      setTemporarySelectedGenes(selectedGenes);
      setGeneSearchTerm('');
      setTemporaryFilterActive(false);
    }
  };

  function chunkArray(array, size) {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }
  const filterIconMap = {
    'Whole Genome': 'fa-dna',
    'Health Risks': 'fa-heartbeat',
    'Newborn': 'fa-baby',
    'Carrier': 'fa-user-md',
    'Cancer': 'fa-crosshairs'
  };
  const searchData = () => {
    let filteredData = genomicData;
  
    if (temporaryFilterActive) {
      filteredData = filterDiseasesByGenes(); 
  }
  
    if (isFilterLocked) {
      filteredData = filterDiseasesByGenes(); 
    }
  
    if (!isFilterLocked && !temporaryFilterActive) {
      switch (selectedFilter) {
        case 'Health Risks':
          filteredData = filteredData.filter(d => d.final_target_group.includes('Health'));
          break; 
        case 'Newborn':
          filteredData = filteredData.filter(d => d.final_target_group.includes('Newborn'));
          break;
        case 'Carrier':
          filteredData = filteredData.filter(d => d.final_target_group.includes('Newborn') + d.final_target_group.includes('Carrier'));
          break; 
        case 'Cancer':
          filteredData = filteredData.filter(d => d.final_target_group.includes('cancer'));
          break;
      }
    }

    return filteredData.filter(d => d.Disease.toLowerCase().includes(searchTerm.toLowerCase()));
  };


  const fetchData = async () => {
    try {
      const response = await fetch('https://default.szaportal.com/getDiseasess', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ GenomicReport: report }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      setGenomicData(data.data);
      processData(data.data)
    } catch (error) {
      console.error('There was an error fetching the genomic data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [report]);




  

 
  const [activeDiseaseIndex, setActiveDiseaseIndex] = useState(null);





  const processData = async (data) => {
    const processedData = await Promise.all(data.map(async (disease) => {
      const isDiseaseHidden = await isHidden(disease.REPORT_TAG, disease.MAX_AF, disease.index, disease.GenomicReport);
      return {
        ...disease,
        hidden: isDiseaseHidden
      };
    }));
    setGenomicData(processedData);
  };
  
  

  const updateReportTag = async (index, newReportTagValue, MAX_AF) => {
    try {
      const response = await fetch('https://default.szaportal.com/updateReportTag', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Include MAX_AF in the body alongside other parameters
        body: JSON.stringify({ index, newReportTagValue, GenomicReport: report, MAX_AF }), 
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchData();  // Refresh data to reflect changes
    } catch (error) {
      console.error('There was an error updating the report tag:', error);
    }
  };


  async function isHidden(reportTag, MAX_AF, index) {
    if (reportTag.toString() == '0') {
        return true; 
    }  
    if (reportTag.toString() == '1' && MAX_AF >= 0.05) {
        return !(await checkVisibilityWithBypassCondition(index));
    }
    return false; 
}
  

  async function checkVisibilityWithBypassCondition(index) {
    console.log("Calling checkVisibilityWithBypassCondition with:", { index });

    try {
        const response = await fetch('https://default.szaportal.com/checkBypassCondition', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ index, GenomicReport: report }) // Use 'report' directly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        return data.isVisible; 
    } catch (error) {
        console.error('There was an error checking bypass condition:', error);
        return false; 
    }
}





  return (
    <div className="genomics-report">

{popupVisible && (
  <div className="genomics-popup">
<div className="genomics-popup__header">
<input
      type="text"
      placeholder="Search or Add Gene..."
      className="genomics-popup__search-bar"
      value={geneSearchTerm}
      onChange={(e) => setGeneSearchTerm(e.target.value.toUpperCase())}
    />
    <button className="genomics-popup__close-btn" onClick={togglePopup}>X</button>
  </div>
  <div className="templatebar" style={{ display: templates.length ? '' : 'none' }}>
  <div className="editbuttontemplate"onClick={toggleEditMode}>
  <FontAwesomeIcon icon={isEditMode ? faArrowLeft : faPen}  />
</div>



<div className="templateselector">
  <button className="scroll-btnleft" onClick={() => scrollTemplates('left')}>&lt;</button>
  
  
  <div className="templateselector-items" ref={templateScrollRef}>
  {templates.map((template, idx) => (
  <div
    className={`template-item ${selectedTemplateIndex === idx ? 'selected' : ''} ${isNewTemplateAdded && idx === templates.length - 1 ? 'new-template' : ''}`}
    key={idx}
  >
    {isEditMode ? (
      <>
        <FontAwesomeIcon icon={faTrash} onClick={() => deleteTemplate(template.TemplateID)} />
        <input
          type="text"
          defaultValue={template.TemplateName}
          onChange={(e) => handleTemplateNameChange(e.target.value, idx)}
          className="template-name-input"
        />
        <FontAwesomeIcon
          className="faSave"
          icon={faSave}
          onClick={() => saveTemplateChanges(template.TemplateID, templates[idx].tempName || template.TemplateName)}
        />
      </>
    ) : (
      <div onClick={() => handleTemplateClick(idx)}>
        {selectedTemplateIndex === idx ? <FontAwesomeIcon icon={faCheck} /> : '+'} {template.TemplateName}
      </div>
    )}
  </div>
))}

  </div>
  <button className="scroll-btnright" onClick={() => scrollTemplates('right')}>&gt;</button>
</div>


</div>


    <div className="genomics-popup__body">
  {renderGeneList()}
</div>



<div className="genomics-popup__footer">
  <button 
    className="dynamic-button"
    onClick={handleDynamicButtonClick}
    
  >
<input
  type="file"
  ref={fileInputRef}
  style={{ display: 'none' }}
  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  onChange={handleFileInputChange}
/>

    <FontAwesomeIcon icon={dynamicButtonIcon} />
  </button>
  <button 
    className="clear-button"
    onClick={() => handleClearFilters()}
  >
    Clear Filter
  </button>
  <button 
    className="apply-button"
    onClick={() => handleApplyGenes()}
  >
    Apply
  </button>
</div>

  </div>
)}
      <div className="gentitle">Genomic Sequencing Report</div>
      <div className="genomics-report">
  <input 
    type="text" 
    className="search-bar"
    placeholder="Search diseases..." 
    value={searchTerm} 
    onChange={e => setSearchTerm(e.target.value)} 
  />


<div className="searchbarunder">  
  <div>
    {['Whole Genome', 'Health Risks', 'Newborn', 'Carrier', 'Cancer'].map((filter) => (
      <button 
        key={filter}
        onClick={() => {
          if (!isFilterLocked) {
            setSelectedFilter(filter);
          }
        }}
        className={`filter-button ${selectedFilter === filter ? 'filter-button-selected' : ''} ${isFilterLocked ? 'filter-button-disabled' : ''}`}
        disabled={isFilterLocked} 
      >
        <i className={`fa fa-icon ${filterIconMap[filter]}`}></i>
        {filter}
      </button>
    ))}
  </div>
  <div>                                    
  <div className="geneinvestage">  

  <div className="geneside">  

  <FontAwesomeIcon 
    icon={faFilter} 
    onClick={togglePopup} 
    className={temporaryFilterActive || isFilterLocked ? 'blue-filter-icon' : ''}
  />
  {temporaryFilterActive || isFilterLocked ? <span className="filter-active-text"></span> : null}
</div>
</div>

  </div>
</div>




</div>


<div className="genomicsstuff">
  

{searchData().length > 0 ? (
  <ul className="genomics-list">
    {searchData().map((disease, index) => (
  <li key={index} className={`genomics-item ${activeDiseaseIndex !== index ? 'closed' : ''}`} style={{ opacity: disease.hidden && activeDiseaseIndex !== index ? 0.5 : 1 }}>
  <div className="diseasetitles" onClick={() => {setActiveDiseaseIndex(prevIndex => prevIndex === index ? null : index);}}>
          <div className="diseasnamet">
          <FontAwesomeIcon icon={disease.hidden ? faEyeSlash : faEye} className="eye-icon" onClick={(e) => {
  e.stopPropagation();
  updateReportTag(disease.index, disease.hidden ? '1' : '0', disease.MAX_AF);
            }} />
            <span>{disease.Disease}</span>


          </div>
          <div className="diseaseinfostatus">
            <div className="diseasestat">
              {disease.ClinVar_CLNSIG && disease.ClinVar_CLNSIG.includes('Conflicting_interpretations_of_pathogenicity') ? 'Unclear Pathogenicity' : 
               !disease.ClinVar_CLNSIG ? 'Novel Variant' : disease.ClinVar_CLNSIG}
            </div>
            <div className="diseasestat">{disease.IMPACT}</div>
          </div>
        </div>
        <div className={`card ${activeDiseaseIndex === index ? 'open' : ''}`}>
        <div className="air">
</div>
<div className="full-width">
    <div className="title">Genomic Position:</div>
    <div className="cell">{disease.ClinVar_CLNHGVS}</div>
  </div>

  <div className="grid">
    <div>
      <div className="title">Genes:</div>
      <div className="cell">{disease.Genes}</div>
    </div>
    <div>
      <div className="title">Pathogenicity:</div>
      <div className="cell">{disease.ClinVar_CLNSIG}</div>
    </div>
    <div>
      <div className="title">Zygosity:</div>
      <div className="cell">{disease.Zygosity}</div>
    </div>
    <div>
      <div className="title">Inheritances:</div>
      <div className="cell">{disease.Inheritances}</div>
    </div>

  </div>
  <div className="full-width">
    <div className="title">Disease Information:</div>
    <div className="cell">{disease.DiseaseInfo}</div>
  </div>





  <div className="bottomdisease">
  <div className="bottomdiseaseleft">
    <label className="switch">
      <input
        type="checkbox"
        checked={!disease.hidden} 
        onChange={(e) => {
          e.stopPropagation();
          updateReportTag(disease.index, disease.hidden ? '1' : '0', disease.MAX_AF);
        }}
      />
      <div className="slider"></div>
      <div className="slider-card">
        <div className="slider-card-face slider-card-front"></div>
        <div className="slider-card-face slider-card-back"></div>
      </div>
    </label>
    <div className="switchstatustext">
      {/* Use disease.hidden to determine the text */}
      {disease.hidden ? 'Hidden' : 'Viewable'}
    </div>
  </div>
  <div className="bottomdiseaseright" onClick={() => {
    setSelectedDisease(disease); // Set the selected disease
    toggleGenomicModal(); // This will open the modal
  }}>
    <div className="ryp">
      <div className="diseaseicon">
        <FontAwesomeIcon icon={faCirclePlus} />
      </div>
      <div className="switchstatustextt">
        Additional Information
      </div>
    </div> 
  </div>
</div>

        </div>
      </li>
    ))}
  </ul>
) : (
  <div className="no-diseases-found">No Diseases Found</div>
)}

</div>
{isGenomicModalVisible && selectedDisease && (
  <div className="genomic-modalBackdrop">
    <div className="genomic-modalContent" onClick={e => e.stopPropagation()}>
      <div className="genomic-modalHeader">
        <span>{selectedDisease.Disease}</span>
        <button className="genomic-closeButton" onClick={toggleGenomicModal}>X</button>
      </div>
      <div className="griddContainer">
        {chunkArray(reorderEntries(selectedDisease), 4).map((chunk, index) => (
          <div className="gridd" key={index}>
            {chunk.map(([key, value], idx) => (
              <div key={idx}>
                <div className="titlee">{key}:</div>
                <div className="celll">{value !== null ? value.toString() : "N/A"}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
)}




    </div>
  );

}

export default Genomics;