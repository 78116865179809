import React, { useState, useEffect, useRef } from 'react';
import './GenomicOption.css';
import { faAdd, faDna, faExclamation, faPrescriptionBottleAlt, faUpload, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GenomicOption({ user }) {
  const [dragOver, setDragOver] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Upload');
  const fileInputRef = useRef();
  const [uploadHistory, setUploadHistory] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);

  useEffect(() => {
    const fetchFiles = () => {
      fetch('https://default.szaportal.com/list-genomic-files')
        .then(response => response.json())
        .then(data => {
          setUploadedFiles(data);
          if (data.length < totalFiles) {
            setProcessedFiles(totalFiles - data.length);
          } else {
            setTotalFiles(data.length);
            setProcessedFiles(0);
          }
        })
        .catch(error => console.error('Error fetching files:', error));
    };
  
    fetchFiles(); // Initial fetch
    const intervalId = setInterval(fetchFiles, 1000); // Fetch every second
  
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [totalFiles]);
  

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleFiles = (files) => {
    const formData = new FormData();
    const validFiles = [];
    const invalidFiles = [];

    for (const file of files) {
      if (getFileIcon(file.name)) {
        formData.append('files', file);
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    }

    if (validFiles.length > 0) {
      fetch('https://default.szaportal.com/upload-genomic-files', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log('Files uploaded successfully:', data);
          fetch('https://default.szaportal.com/list-genomic-files')
            .then(response => response.json())
            .then(data => setUploadedFiles(data))
            .catch(error => console.error('Error fetching files:', error));
        })
        .catch(error => console.error('Error uploading files:', error));
    }

    if (invalidFiles.length > 0) {
      alert('Some files are invalid and were not uploaded.');
    }
  };

  const handleFileInputChange = (e) => {
    handleFiles(e.target.files);
  };

  const getFileIcon = (fileName) => {
    if (fileName.includes('nodup')) {
      return faDna;
    } else if (fileName.includes('GWAS')) {
      return faExclamation;
    } else if (fileName.includes('pharmaco')) {
      return faPrescriptionBottleAlt;
    } else if (fileName.includes('traits')) {
      return faUserEdit;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchUploadHistory = () => {
      fetch('https://default.szaportal.com/GetUploadHistory', {
        method: 'POST',
      })
        .then(response => response.json())
        .then(data => setUploadHistory(data))
        .catch(error => console.error('Error fetching upload history:', error));
    };

    if (selectedTab === 'UploadHistory') {
      fetchUploadHistory();
    }
  }, [selectedTab]);

  return (
    <div className="GenomicOptionContent">
      <div className="genomicdropd" 
           onDragEnter={handleDragEnter}
           onDragOver={handleDragOver}
           onDragLeave={handleDragLeave}
           onDrop={handleDrop}
           onClick={() => fileInputRef.current.click()}>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          multiple
          onChange={handleFileInputChange}
        />
        {dragOver ? (
          <div className="abouttodrop">
            <FontAwesomeIcon icon={faAdd} className="optionicons" />
            <div className="optionf">Please Drop Result Files</div>
          </div>
        ) : (
          <div className="abouttodrop">
            <FontAwesomeIcon icon={faUpload} className="optionicons" />
            <div className="optionf">Please Drag and Drop Result Files or Click to Upload</div>
            <div className="optionf">ex.</div>
            <div className="optionfg">P001_XXX. (...) _Inheritance_4_nodup.txt</div>
            <div className="optionfg">P001_XXX. (...) _GWAS.txt</div>
            <div className="optionfg">P001_XXX. (...) _pharmaco.txt</div>
            <div className="optionfg">P001_XXX. (...) _traits.txt</div>
          </div>
        )}
      </div>

      <div className="genomicupload">
        <div className="genomicstatustitle">
          Uploading Status
        </div>

        <div className="QuickyPDFChoose2">
          <div
            className="Choose2"
            onClick={() => setSelectedTab('Upload')}
            style={{
              color: selectedTab === 'Upload' ? 'grey' : '#bebdbd',
              backgroundColor: selectedTab === 'Upload' ? 'white' : 'transparent',
            }}
          >
            Upload
          </div>
          <div
            className="Choose2"
            onClick={() => setSelectedTab('UploadHistory')}
            style={{
              color: selectedTab === 'UploadHistory' ? 'grey' : '#bebdbd',
              backgroundColor: selectedTab === 'UploadHistory' ? 'white' : 'transparent',
            }}
          >
            Upload History
          </div>
        </div>

        <div className="genomicstatuslist">
          {selectedTab === 'Upload' ? (
            uploadedFiles.length === 0 ? (
              <div className="Nofiles">No Files</div>
            ) : (
              uploadedFiles.map((file, index) => (
                <div className="fileitem" key={index}>
                  <div className="filecategory">
                    <FontAwesomeIcon icon={getFileIcon(file)} />
                  </div>
                  <div className="fileinfo">
                    <div className="fileinfo">
                      {file.split(".")[0]}
                    </div>
                    <div className="filetruncname">
                      {file.substring(0, file.indexOf('.') + 1) + '...' + file.substring(file.length - 2 - file.indexOf('.'))}
                    </div>
                  </div>
                  <div className="filecategorystatus">
                    <div className="lloader">
                      <li className="balll"></li>
                      <li className="balll"></li>
                      <li className="balll"></li>
                    </div>
                  </div>
                </div>
              ))
            )
          ) : (

            

            uploadHistory.length === 0 ? (
              <div className="Nofiles">No Upload History</div>
            ) : (
              uploadHistory.slice().reverse().map((historyItem, index) => {
                const fileName = historyItem.filepath.split('/').pop(); // Extract the filename
            
                return (
                  <div className="fileitemg" key={index}>
                    <div className="fileinfog">
                      {fileName}
                    </div>
                    <div className="filetruncnameg">
                      Uploaded {new Date(historyItem.upload_time).toLocaleString()}
                    </div>
                  </div>
                );
              })
            )
            




          )}
        </div>

        <div className="loaddting">
        <div className="LoadingbarContainer">
  <div className="Loadingbar" style={{ width: `${(processedFiles / totalFiles) * 100}%` }}>
    <div className="percentageindicator">
      <div className="percentage">
        {Math.round((processedFiles / totalFiles) * 100)}%
      </div>
      <div className="fraction">
        ({processedFiles}/{totalFiles})
      </div>
    </div>
  </div>
</div>

        </div>
      </div>
    </div>
  );
}

export default GenomicOption;
