import React, { useState, useEffect } from 'react';
import './Transaction.css';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';

function Transaction() {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await fetch('https://filemanager.szaportal.com/api/transactions', {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTransactions(data);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };

        fetchTransactions();
    }, []);

    const getIconForType = (type) => {
        switch (type) {
            case 'Download':
                return faDownload;
            case 'Deletion':
                return faTrash;
            case 'Uploaded':
                return faUpload;
            default:
                return null;
        }
    };

    return (
        <div className="Transaction">
            <div className="Transaction-list">
                {transactions.length > 0 ? (
                    transactions.map(transaction => (
                        <div key={transaction.ID} className="Transaction-item">
                            <FontAwesomeIcon icon={getIconForType(transaction.Type)} className="Transaction-icon" />
                            <div className="Transaction-details">
                                <div className="Transaction-type">{transaction.Type}</div>
                                <div className="Transaction-description">{transaction.Description}</div>
                                <div className="Transaction-user">
                                    {transaction.UserType === 'Clinician' && (
                                        <span>{transaction.UserDetails?.email}</span>
                                    )}
                                    {transaction.UserType === 'Customer' && (
                                        <span>{transaction.UserDetails?.email} ({transaction.UserDetails?.IPAddress})</span>
                                    )}
                                </div>
                                <div className="Transaction-date">{new Date(transaction.CreatedAt).toLocaleString()}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="Transaction-no-items">No transactions found.</div>
                )}
            </div>
        </div>
    );
}

export default Transaction;
