import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheckCircle, faRemove } from '@fortawesome/free-solid-svg-icons';
import './StatusUpdateNotification.css';

function StatusUpdateNotification({ isVisible, userDetails, statusMessage, onConfirm, onClose, purchaseDetails }) {
  const getProfilePicUrl = (imageName) => {
    const isUrl = /^https?:\/\//.test(imageName);
    return isUrl ? imageName : `https://default.szaportal.com/profile_pics/${imageName}`;
  };

  const getInitials = (firstName = '', lastName = '') => {
    const initials = [
      firstName ? firstName.charAt(0) : '',
      lastName ? lastName.charAt(0) : ''
    ].filter(Boolean).join('').toUpperCase();

    return initials || 'N/A';
  };

  const getStatusMessage = (status) => {
    switch (status?.toString()) {
      case "1":
        return "Order received";
      case "2":
        return "Sent cargo";
      case "3":
        return "Cargo delivered";
      case "4":
        return "Lab received";
      case "5":
        return "Data delivered";
      default:
        return "Unknown status";
    }
  };

  const CurrentStatusMessage = getStatusMessage(purchaseDetails.Status);
  const createdAt = purchaseDetails.created_at ? new Date(purchaseDetails.created_at).toISOString().split('T')[0] : '';
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className={`StatusUpdateNotification ${isVisible ? 'visible' : ''}`}>
      <div className="StatusUpdateNotificationContent">
        {statusMessage && (
          <div className="SUNL" onClick={onClose}>
            <FontAwesomeIcon icon={faRemove} />
          </div>
        )}
        <div className="SUML">
          <div className="SUMLTP">
            {!imageError && userDetails?.profile_picture ? (
              <img
                src={getProfilePicUrl(userDetails.profile_picture)}
                className="order-profile-pic"
                onError={handleImageError}
                alt={`${userDetails?.First_Name} ${userDetails?.Last_Name}`}
              />
            ) : (
              <div className="order-initials-wrapper">
                <div className="patient-initialsd">
                  {getInitials(userDetails?.First_Name, userDetails?.Last_Name)}
                </div>
              </div>
            )}
          </div>
          <div className="SUMLTR">
            <div className="SUMLTRT">
              <div className="SUMLTRT1">
                {userDetails?.First_Name ? userDetails.First_Name.charAt(0).toUpperCase() + userDetails.First_Name.slice(1) : ''} {userDetails?.Last_Name ? userDetails.Last_Name.charAt(0).toUpperCase() + userDetails.Last_Name.slice(1) : ''}
              </div>
              <div className="SUMLTRT2">
                {createdAt}
              </div>
            </div>
            <div className="SUMLTRM">
              {`${purchaseDetails.Kit_Type}`}
            </div>
            <div className="SUMLTRM">
              {`${purchaseDetails.Address}`}
            </div>
            <div className="SUMLB">
              <div className="CSM">{CurrentStatusMessage}</div>
              {statusMessage && <><FontAwesomeIcon icon={faArrowRight} /> {statusMessage}</>}
            </div>
          </div>
        </div>
        {statusMessage && (
          <div className="SUMR" onClick={onConfirm}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        )}
      </div>
    </div>
  );
}

export default StatusUpdateNotification;
