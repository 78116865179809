import React, { useState, useEffect } from 'react';
import { useZxing } from 'react-zxing';
import Lottie from 'lottie-react';
import animationData from '../Orders/Animation - 1720918034647.json';
import animationDataBottom from '../Orders/Animation - 1720996224157.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPaperPlane, faMicroscope, faInfoCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import './CamScan.css';
import UserDetailsCover from './UserDetailsCover';
import StatusUpdateNotification from './StatusUpdateNotification';

function CamScan({ active, onBackClick }) {
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [showAnimation, setShowAnimation] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedButton, setSelectedButton] = useState(1);
  const [userDetails, setUserDetails] = useState({});
  const [purchaseDetails, setPurchaseDetails] = useState({});
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // Add state for camera facing mode
  const [showBottomAnimation, setShowBottomAnimation] = useState(false); // Add state for bottom animation

  useEffect(() => {
    // Close the notification whenever a different button is selected
    setShowNotification(false);
    // Update the status message whenever the selected button changes
    setStatusMessage(getStatusMessage(selectedButton));
  }, [selectedButton]);

  useEffect(() => {
    // Set the selected button to 'status check' when back button is clicked
    if (!active) {
      setSelectedButton(1);
    }
  }, [active]);

  const getProfilePicUrl = imageName => {
    // Check if imageName is a complete URL
    const isUrl = /^https?:\/\//.test(imageName);
    return isUrl ? imageName : `https://default.szaportal.com/profile_pics/${imageName}`;
  };

  const { ref } = useZxing({
    onDecodeResult(result) {
      setScannedBarcode(result.getText());
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 4000); // hide animation after 4 seconds
      fetchDetails(result.getText());
    },
    paused: !active, // control scanning with active prop
    facingMode: cameraFacingMode, // Use the camera facing mode state
  });

  const fetchDetails = async (barcode) => {
    try {
      const response = await fetch(`https://default.szaportal.com/app_purchases/${barcode}`);
      const data = await response.json();
      if (response.ok) {
        setPurchaseDetails(data.purchase);
        setUserDetails(data.user);
        if (selectedButton === 1) {
          setShowUserDetails(true); // Show user details cover only for Check Status
        } else {
          setShowNotification(true); // Show notification for other actions
        }
      } else {
        console.error('Error fetching data:', data.error);
        setStatus({ error: data.error });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setStatus({ error: 'Error fetching data' });
    }
  };

  const updateStatus = async (newStatus) => {
    try {
      const response = await fetch(`https://default.szaportal.com/update_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          barcode: scannedBarcode,
          status: newStatus,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setStatus({ success: data.message });
      } else {
        setStatus({ error: data.error });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      setStatus({ error: 'Error updating status' });
    }
  };

  const getStatusMessage = (buttonStatus) => {
    switch (buttonStatus) {
      case 2:
        return "Sent cargo";
      case 3:
        return "Lab received";
      case 4:
        return "Data delivered";
      default:
        return "";
    }
  };

  const handleCameraFlip = () => {
    setCameraFacingMode(prevMode => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  const handleConfirm = () => {
    let newStatus;
    if (selectedButton === 2) newStatus = 2;
    if (selectedButton === 3) newStatus = 4;
    if (selectedButton === 4) newStatus = 5;
    updateStatus(newStatus);
    setShowNotification(false);
    setShowBottomAnimation(true); 
    setTimeout(() => {
      setShowBottomAnimation(false); 
    }, 3000);
  };

  return (
    <div className="CamScan">
      {showAnimation && (
        <div className="animationContainer">
          <Lottie animationData={animationData} loop={false} style={{ width: '50vh', height: '50vh' }} speed={0.75} />
        </div>
      )}
      <div className="CamScanTop">
        <div
          onClick={() => setSelectedButton(1)}
          className="CamScanTopButton"
          style={{ opacity: selectedButton === 1 ? 1 : 0.2 }}
        >
          <div className="CamScanTopButtonIn">
            <div className="CamScanTopButtonTo"><FontAwesomeIcon icon={faInfoCircle} /></div>
            <div className="CamScanTopButtonTe">Check Status</div>
          </div>
        </div>
        <div
          onClick={() => setSelectedButton(2)}
          className="CamScanTopButton"
          style={{ opacity: selectedButton === 2 ? 1 : 0.2 }}
        >
          <div className="CamScanTopButtonIn">
            <div className="CamScanTopButtonTo"><FontAwesomeIcon icon={faPaperPlane} /></div>
            <div className="CamScanTopButtonTe">Send Delivery</div>
          </div>
        </div>
        <div
          onClick={() => setSelectedButton(3)}
          className="CamScanTopButton"
          style={{ opacity: selectedButton === 3 ? 1 : 0.2 }}
        >
          <div className="CamScanTopButtonIn">
            <div className="CamScanTopButtonTo"><FontAwesomeIcon icon={faMicroscope} /></div>
            <div className="CamScanTopButtonTe">Lab Received</div>
          </div>
        </div>
        <div
          onClick={() => setSelectedButton(4)}
          className="CamScanTopButton"
          style={{ opacity: selectedButton === 4 ? 1 : 0.2 }}
        >
          <div className="CamScanTopButtonIn">
            <div className="CamScanTopButtonTo"><FontAwesomeIcon icon={faCheckCircle} /></div>
            <div className="CamScanTopButtonTe">Data Delivered</div>
          </div>
        </div>
      </div>
      <div className="CamScanMid">
        {active && <video ref={ref} style={{ width: '100%', height: '100%' }} />}
        <button className="flipCameraButton" onClick={handleCameraFlip}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </button>
      </div>
      <div className="CamScanBot">   
        <StatusUpdateNotification
          isVisible={showNotification}
          userDetails={userDetails}  
          statusMessage={statusMessage}
          onConfirm={handleConfirm}
          onClose={() => setShowNotification(false)}
          purchaseDetails={purchaseDetails}
        />
      </div>
      {showBottomAnimation && (
        <div className="bottomAnimationContainer">
          <Lottie animationData={animationDataBottom}  style={{ width: '100%', height: '10vh' }} speed={0.75} />
        </div>
      )}
      <UserDetailsCover
        isVisible={showUserDetails}
        userDetails={userDetails}
        purchaseDetails={purchaseDetails}
        onClose={() => setShowUserDetails(false)}
      />
    </div>
  );
}

export default CamScan;
