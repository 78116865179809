import React, { useState, useEffect, useRef } from 'react';
import './UserDetailsCover.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Barcode from 'react-barcode';

const getProfilePicUrl = (imageName) => {
  const isUrl = /^https?:\/\//.test(imageName);
  return isUrl ? imageName : `https://default.szaportal.com/profile_pics/${imageName}`;
};

function UserDetailsCover({ isVisible, userDetails = {}, purchaseDetails = {}, onClose }) {
  const [imageError, setImageError] = useState(false);
  const contentRef = useRef(null);

  const handleImageError = () => {
    setImageError(true);
  };

  const getStatusMessage = (status) => {
    switch (status?.toString()) {
      case "1":
        return "Order received";
      case "2":
        return "Sent cargo";
      case "3":
        return "Cargo delivered";
      case "4":
        return "Lab received";
      case "5":
        return "Data delivered";
      default:
        return "Unknown status";
    }
  };

  const getInitials = (firstName = '', lastName = '') => {
    const initials = [
      firstName ? firstName.charAt(0) : '',
      lastName ? lastName.charAt(0) : ''
    ].filter(Boolean).join('').toUpperCase();

    return initials || 'N/A';
  };

  const statusMessage = getStatusMessage(purchaseDetails.Status);
  const barcodeValue = purchaseDetails.Barcode || '';
  const createdAt = purchaseDetails.created_at ? new Date(purchaseDetails.created_at).toISOString().split('T')[0] : '';

  const handleClickOutside = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  return (
    <div className={`UserDetailsCover ${isVisible ? 'visible' : ''}`}>
      <div className="UserDetailsCoverIn" ref={contentRef}>
        <div className="userDetails">
          <div className="userDetailsL">
            <div className="userDetailsLT">
              <div className="userDetailsLTP">
                {!imageError && userDetails.profile_picture ? (
                  <img 
                    src={getProfilePicUrl(userDetails.profile_picture)}  
                    className="order-profile-pic" 
                    onError={handleImageError}
                    alt={`${userDetails.First_Name} ${userDetails.Last_Name}`}
                  />
                ) : (
                  <div className="order-initials-wrapper">
                    <div className="patient-initialsd">
                      {getInitials(userDetails.First_Name, userDetails.Last_Name)}
                    </div>
                  </div>
                )}
              </div>
              <div className="userDetailsRTP">
                <div className="UDN">
                  {userDetails.First_Name ? (
                    `${userDetails.First_Name.charAt(0).toUpperCase()}${userDetails.First_Name.slice(1).toLowerCase()}`
                  ) : 'Unknown'}&nbsp;
                  {userDetails.Last_Name ? (
                    `${userDetails.Last_Name.charAt(0).toUpperCase()}${userDetails.Last_Name.slice(1).toLowerCase()}`
                  ) : ''}
                </div>
                <div className="UDE"> {userDetails.Email || 'No email provided'}</div>
                <div className="UDS"> {userDetails.Sex ? `${userDetails.Sex}, ` : ''}{userDetails.Age || ''} </div>
              </div>
            </div>
            <div className="userDetailsLB">
              <div className="userDetailsBarCode">
                <div className="DBC">
                  {barcodeValue && (
                    <Barcode 
                      value={barcodeValue}
                      background="transparent"
                      className="BCDD"
                    />
                  )}
                </div>
              </div>
              <div className="userDetailsAddress">
                <div className="userDetailsAddressUp">
                  <div className="USDUP">Delivery Address</div>
                  <div className="USDDO"> {purchaseDetails.Address || 'No address provided'}</div>
                </div>
                <div className="userDetailsAddressBo">
                  <div className="userDetailsAddressBoL">
                    <div className="USDUP">Kit Type</div>
                    <div className="USDDO"> {purchaseDetails.Kit_Type || 'Unknown'}</div>
                  </div>
                  <div className="userDetailsAddressBoR">
                    <div className="USDUP">Bought At</div>
                    <div className="USDDO"> {createdAt}</div>
                  </div>
                </div>
              </div>
              <div className="userDetailsBot">
                <div className="userDetailsBotIn">
                  <div className="userDetailsBotTop">Order Status</div>
                  <div className="userDetailsBotBot">
                    {statusMessage.toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="userDetailsR">
            <div onClick={onClose}><FontAwesomeIcon icon={faChevronLeft} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetailsCover;
